import React from 'react';

const MessageParser = ({ children, actions }) => {

  let historyMessage = children.props.state.messages
  const input = document.getElementsByClassName("react-chatbot-kit-chat-input")[0];

  const parse = async (message) => {

    input.readOnly = true
    actions.next(message);

  };
  
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
