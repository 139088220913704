import axios from "axios";

const cactusUrl = 'https://cactus.gydtech.io/demo-np';

export const recommendProducts = async (query, filters = {}) => {
  try {
    const result = await axios.post(
      `${cactusUrl}/products/search`,
      { query, filters },
      {
        headers: {
          Authorization: `Bearer 8qrw-LSxIgos_Xx5lnGTHWITk2sXNg92xprQNcRAhjlIDV7wsXDuHA`, // Token d'authentification
        },
      }
    );

    return result.data; // Retourne les données obtenues
  } catch (error) {
    console.error("Error recommending products by text:", error);
    throw error; // Relance l'erreur pour que l'appelant puisse la gérer
  }
};

export const recommendProductsByNeeds = async (profile, filters = {}) => {
  try {
    const result = await axios.post(
      `${cactusUrl}/profile/recommend`,
      { profile, filters },
      {
        headers: {
          Authorization: `Bearer 8qrw-LSxIgos_Xx5lnGTHWITk2sXNg92xprQNcRAhjlIDV7wsXDuHA`, // Token d'authentification
        },
      }
    );

    return result.data; // Retourne les données obtenues
  } catch (error) {
    console.error("Error recommending products by text:", error);
    throw error; // Relance l'erreur pour que l'appelant puisse la gérer
  }
};
