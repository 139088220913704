import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './popupWelcome.css';
import '../../App.css'
import {titleNormalized} from "../../select";

export default function ResponsiveDialog({data, handleChangeAdvisor}) {
  const [open, setOpen] = useState(true);
  const [time1, setTime1] = useState(false);
  const [time2, setTime2] = useState(false);
  const [time3, setTime3] = useState(false);
  const [time4, setTime4] = useState(false);
  const [sound, setSound] = useState(0);
  const [advisor, setAdvisor] = useState('');
  const [cookies, setCookies] = useState(false);
  const [showPdf, setShowPdf] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getAppName = (app) => {
    switch (app) {
      case 'chatbot-hair': return getTextApp("chatbot_hair");
      case 'chatbot-hair-ybera': return 'Confidences';
      case 'chatbot-beauty-skin': return 'Beauty';
      case 'chatbot-skin': return 'Skin';
      default: return 'Confidences';
    }
  }


  useEffect(() => {
    if(localStorage.getItem('advisor')) {
      setTimeout(() => setTime3(true), 2000);
      setTimeout(() => setTime4(true), 4000);
    } else {
      setTimeout(() => setTime1(true), 2000);
      setTimeout(() => setTime2(true), 4000);
    }
  }, [])

    const selectAdvisor = async(ad) => {
      setAdvisor(ad);
      setTimeout(() => {setTime3(true)}, 3000);
      setTimeout(() => {setTime4(true)}, 4000);
  }

  const setSoundAction = (action) => {
    setSound(action);

    if(action === 1){
      localStorage.setItem('mute', 'false');
    }
    else{
      localStorage.setItem('mute', 'true');
    }
    handleClose()
  }

  const handleClose = () => {
    setTimeout(() => {
      handleChangeAdvisor(advisor);
      setOpen(false);
    }, 2000);
  };

  return (
    <React.Fragment>
      <Dialog
  fullScreen={fullScreen}
  open={open}
  onClose={handleClose}
  aria-labelledby="responsive-dialog-title"
  PaperProps={{
    sx: {
      background: "url('/assets/images/background/backgroundConfidence.gif')",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      fontFamily: 'customFont'
    },
  }}
>
        <DialogContent>
          {!time3 && <Box sx={{width: 320, height: 250, margin: 'auto', position: 'absolute', top: (advisor ? '-250px' : 0), bottom: 0, left: 0, right: 0}}>
            <div className={'txt'} style={{animation: 'fadeIn 3s', fontSize: (time1 ? '21px' : '41px'), lineHeight: '54px', position: (time1 ? 'fixed' : 'relative'), opacity: (time1 ? '0.6' : '1')}}>Bienvenue chez, <b>{getAppName(titleNormalized)}</b></div>
            <div className={'txt'} style={{fontSize: (advisor ? '21px' : '31px'), lineHeight: (advisor ? '24px' : '42px'), opacity: (time1 && !advisor ? '1' : (advisor ? '0.6' : 0)), position: (time2 ? 'initial' : 'relative')}}>Avant de commencer, sélectionnez votre coiffeur.</div>
            <div className={'coiffeurs'} style={{opacity: (time2 ? '1' : '0'), height: (time2 ? '70px' : '0')}}>
              {data && data.map((ad, index) => (
                  <div key={index} className={'coiffeur'} style={{opacity: (!advisor || advisor === ad.firstname ? '1' : '0.25'), backgroundImage: 'url(' + ad.profile_img +')', backgroundSize: 'cover'}}
                       onClick={() => {selectAdvisor(ad.firstname)}}>
                    {!ad.profile_img && <span>{ad.firstname}</span>}
                  </div>
              ))}
            </div>
            {advisor && <div className={'txt'} style={{marginTop: '62px', fontSize: '26px'}}>Vous avez sélectionné, <br/><b>{advisor}</b></div>}
          </Box>}
          {time3 && <Box sx={{
            width: 320,
            height: 250,
            margin: 'auto',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}>
            <div className={'txt'} style={{fontSize: '26px'}}>Avant de commencer acceptez-vous d’activer le son ?</div>
            {time4 && <div className={'chois-btns'}>
              <button onClick={() => setSoundAction(1)} style={{opacity: ((!sound || sound === 1) ? '1' : '0.6')}}>Oui
              </button>
              <button onClick={() => setSoundAction(2)} style={{opacity: ((!sound || sound === 2) ? '1' : '0.4')}}>Non
              </button>
              <div style={{marginTop: '20px', color: 'white'}}> En utilisant ce service Gydtech vous acceptez les
                <a onClick={() => setShowPdf('CGU')} target="_blank" style={{textDecoration: "underline"}}> CGU</a> et la
                <a onClick={() => setShowPdf('PCD')} target="_blank" style={{textDecoration: "underline"}}> PCD</a>.
              </div>
            </div>}
          </Box>}
          {showPdf && <div style={{background: "white", position: "relative", zIndex: 99999999}}>
            <span onClick={() => setShowPdf('')} style={{float: 'right', padding: '8px', fontSize: '20px'}}>x</span>
            {
              showPdf === 'CGU' && <div style={{padding: '35px 10px', whiteSpace: 'break-spaces', fontSize: '16px'}}>
                  <b>Conditions Générales d'Utilisation</b><br/><br/>

                  Date de dernière modification : 30/05<br/>
                  ARTICLE 1. MENTIONS LÉGALES<br/>
                  MENTIONS LÉGALES<br/>
                  URL du Site : URL gyd beauty<br/>
                  Contact : support@gydtech.ia<br/>
                  Directeur de la Publication : [Nom]<br/><br/>
                  Éditeur : GYD Tech, société au capital de 1111,00 euros, dont le siège social est situé au 59 route
                  du<br/><br/>
                  moulin carron 69570 DARDILLY inscrite au Registre du Commerce et des Sociétés de Lyon sous le numéro<br/>
                  [numéro d'immatriculation].<br/>
                  Hébergeur : [Nom de l'hébergeur], [adresse complète], inscrite au Registre du Commerce et des Sociétés
                  de<br/>
                  [ville] sous le numéro [numéro d'immatriculation].<br/><br/>
                  ARTICLE 2. ACCÈS AU SITE<br/>
                  2.1. L'accès au site et à l'application GYD Beauty est réservé aux utilisateurs âgés de 16 ans ou
                  plus.<br/>
                  Vous pouvez accéder librement et gratuitement au Site, sans inscription ni création de compte
                  préalable.<br/>
                  Les frais d’accès et d’utilisation du réseau de télécommunication restent néanmoins à votre charge.<br/>
                  2.2. Certaines rubriques nécessitent un compte avec des codes d'accès personnels. Vous êtes responsable de<br/>
                  la confidentialité de vos codes d'accès et de toutes les activités effectuées sous ces
                  identifiants.<br/><br/>
                  ARTICLE 3. SERVICES DE DIAGNOSTIC CAPILLAIRE<br/>
                  3.1. GYD Beauty propose un service de diagnostic capillaire utilisant l'intelligence artificielle
                  pour<br/>
                  analyser et recommander des produits et soins personnalisés.<br/>
                  3.2. Ce service nécessite la création d'un compte. Les photos et informations collectées sont
                  stockées<br/>
                  dans votre espace personnel sécurisé et peuvent être supprimées sur demande.<br/>
                  3.3. GYD Tech peut conserver des exemplaires anonymisés des photos pour améliorer ses algorithmes et<br/>
                  recherches.<br/><br/>
                  ARTICLE 4. PROPRIÉTÉ INTELLECTUELLE<br/>
                  4.1. Tous les droits de propriété intellectuelle liés au site et à l'application restent la propriété<br/>
                  exclusive de GYD Tech.<br/>
                  4.2. Il est interdit de reproduire, distribuer, modifier ou utiliser le contenu du site et de<br/>
                  l'application à des fins commerciales sans autorisation préalable.<br/><br/>
                  ARTICLE 5. VOS ENGAGEMENTS<br/>
                  5.1. Vous vous engagez à utiliser le site et l'application de manière licite et respectueuse des autres
                  utilisateurs.<br/>
                  Vous êtes entièrement responsable de votre utilisation du site et du contenu que vous mettez en
                  ligne.<br/>
                  Vous vous engagez ainsi à respecter la législation en vigueur, dont notamment, mais non limitativement :
                  les dispositions relatives à la Loi pour la Confiance dans l’Économie Numérique du 21 juin 2004 numéro
                  2004-575, et tous ses décrets d’application,<br/>
                  la réglementation sur la vente à distance, et la législation applicable en matière de contrats conclus
                  avec des consommateurs,<br/>
                  à la législation en vigueur sur la publicité,<br/>
                  à la législation en vigueur sur la propriété intellectuelle,<br/>
                  les droits des tiers (droit à l’image, droit à la vie privée, ainsi que la Loi du 21 juillet 1881).<br/>
                  Vous vous engagez plus particulièrement à :<br/>
                  Respecter les règles habituelles de courtoisie. Éviter les insultes et vulgarités et ce, même si elles
                  sont d’ordre humoristique,<br/>
                  Véhiculer des propos racistes, violents, xénophobes, malveillants, vulgaires, obscènes ou encore
                  illicites,<br/>
                  Ne publier aucune photo ou aucun CONTENU illicites<br/>
                  Ne pas publier de commentaires comportant : des informations de nature diffamatoire, menaçante,
                  abusive,<br/>
                  obscène, raciste, incitant à la pédophilie, à la discrimination, à la haine ou à la violence en raison de
                  la race, de l’ethnie, de la religion ou incitant à l’apologie du nazisme ou la contestation de crimes
                  contre l’humanité ; tout contenu contrefaisant ; toute information portant atteinte aux bonnes mœurs, ou
                  d’une manière générale, illicite ;<br/>
                  Utiliser le Site pour faire de la politique, de la propagande ou du prosélytisme ;<br/>
                  Publier des contenus publicitaires ou promotionnels pour des produits et/ou services concurrents de la
                  marque présentée sur le Site ;<br/>
                  Prendre garde à ne pas reproduire, représenter ou diffuser sans le consentement de son auteur des œuvres
                  protégées par le droit de la propriété intellectuelle ou des marques enregistrées ;<br/>
                  S’abstenir de collecter, stocker et/ou diffuser les données personnelles d’autrui ou de diffuser les
                  données personnelles le concernant tel que nom et adresse, adresse, email, sans leur autorisation ;<br/>
                  Ne pas diffuser ni utiliser de programmes informatiques comportant des fonctionnalités destructrices
                  (notamment, virus, Cheval de Troie, tout autre logiciel ou code nuisible aux logiciels ou ordinateurs
                  d’autrui, robots destinés à faire afficher des écrans multiples, etc.) ;<br/>
                  Ne pas usurper l’identité d’un autre compte ou de toute autre personne (ne pas tenter par exemple
                  d’usurper une adresse e-mail déjà employée ou d’une personne connue) et ce, notamment, dans l’intention
                  d’induire en erreur ou de causer un préjudice à autrui ;<br/>
                  Si vous estimez qu’un Contenu Utilisateur porte atteinte aux principes énoncés ci-dessus et à vos droits
                  ou aux droits d’un tiers (par exemple contrefaçon, insulte, atteinte à la vie privée), vous pouvez nous le
                  notifier par mail à contact@abbi.care soit en adressant un courrier circonstancié à l’adresse suivante :
                  Service client de ABBI , ADRESSE - CP VILLE en indiquant dans votre courriel/courrier la date à laquelle
                  vous avez constaté ce contenu, votre identité, l’adresse URL du contenu litigieux, sa description et
                  l’identifiant de son auteur.<br/>
                  Nous nous réservons le droit de supprimer sans préavis et librement tout CONTENU ou tout compte ne
                  respectant pas les présentes CGU, ou qui seraient contraires aux bonnes mœurs.
                  5.2. Garanties<br/>
                  Vous nous garantissez contre tous troubles, revendications et évictions quelconques.
                  Vous nous garantissez, le cas échéant, que les traitements auxquels vous procédez respectent les règles en
                  vigueur<br/>
                  Vous êtes seul responsable de votre utilisation du site.<br/><br/>
                  ARTICLE 6. RESPONSABILITÉS<br/>
                  6.1. GYD Beauty s'efforce de maintenir le site et l'application à jour et de corriger les erreurs dès
                  qu'elles sont signalées. Toutefois, nous ne garantissons pas que les informations fournies soient toujours
                  exactes ou complètes.<br/>
                  6.2. L'outil de diagnostic capillaire est basé sur des algorithmes d'intelligence artificielle et fournit
                  des recommandations à titre indicatif. Ces recommandations ne remplacent pas un avis médical
                  professionnel.<br/><br/>
                  ARTICLE 7. DONNÉES PERSONNELLES<br/>
                  7.1. Les données personnelles collectées via l'application sont traitées conformément à notre Politique de
                  Confidentialité.<br/><br/>
                  ARTICLE 8. COOKIES
                  8.1. L'application utilise des cookies pour améliorer l'expérience utilisateur. Pour plus d'informations,
                  consultez notre Politique de Cookies.<br/><br/>
                  ARTICLE 9. DROIT APPLICABLE ET LITIGES<br/>
                  9.1. Les présentes CGU sont régies par la loi française. En cas de litige, les tribunaux français seront
                  compétents.<br/>
                  Pour toute question ou réclamation, contactez notre service client par e-mail à support@gydtech.io.
                  <br/><br/>
                  Ces CGU constituent l'intégralité de l'accord entre l'Utilisateur et GYD Tech concernant l'utilisation de
                  l'application GYD Beauty.

                </div>
            }
            {
                showPdf === 'PCD' && <div style={{padding: '35px 10px', whiteSpace: 'break-spaces', fontSize: '16px'}}>
                  <b>Politique de Confidentialité - GYD Beauty</b><br/><br/>


                  Date de dernière modification : 30/05<br/><br/>


                  URL du Site : URL gyd beauty<br/>
                  Contact : support@gydtech.ia<br/>
                  Directeur de la Publication : [Nom]<br/>
                  Éditeur : GYD Tech, société au capital de 1111,00 euros, dont le siège social est situé au 59 route
                  du<br/>
                  moulin carron 69570 DARDILLY inscrite au Registre du Commerce et des Sociétés de Lyon sous le numéro<br/>
                  [numéro d'immatriculation].<br/>
                  Hébergeur : [Nom de l'hébergeur], [adresse complète], inscrite au Registre du Commerce et des Sociétés
                  de<br/>
                  [ville] sous le numéro [numéro d'immatriculation].<br/><br/>


                  Les engagements de GYD Tech
                  Désireux de protéger la vie privée de ses partenaires, GYD Tech s’engage à assurer le meilleur niveau de
                  protection à vos données personnelles en conformité avec la loi française et la réglementation européenne
                  applicables, notamment le Règlement Général sur la Protection des Données (RGPD), applicable depuis le 25
                  mai 2018.<br/>
                  Afin d’exposer plus avant nos pratiques en matière de protection des données à caractère personnel et de
                  respect de la vie privée, nous présentons ci-dessous les différents types de données à caractère personnel
                  que nous pouvons obtenir directement de votre part ou suite à votre interaction avec nous, la manière dont
                  nous pouvons les utiliser, les personnes avec qui nous les pouvons les partager, la manière dont nous les
                  protégeons et assurons leur sécurité, ainsi que les droits dont vous bénéficiez concernant vos données à
                  caractère personnel. Vous pouvez bien sûr ne pas être concerné par toutes ces situations. La présente
                  politique de protection des données à caractère personnel a pour but de vous donner un aperçu de
                  l’ensemble des situations dans lesquelles nous pourrions être amenés à interagir ensemble.
                  <br/><br/>
                  Responsable du traitement
                  <br/>
                  GYD Beauty est une application développée par la société GYD Tech. GYD Tech est responsable des données à
                  caractère personnel que vous nous communiquez via cette application. Les termes « GYD Tech », « nous » ou
                  « nos » utilisés dans les présentes désignent la société GYD Tech. Conformément à la réglementation
                  applicable en matière de protection des données à caractère personnel, GYD Tech est le « responsable du
                  traitement ».
                  <br/><br/>
                  GYD Tech<br/>
                  59 CHEMIN DU MOULIN CARRON<br/>
                  69570 DARDILLY<br/>
                  <br/>
                  Informations personnelles<br/>
                  Une donnée à caractère personnel désigne toute information relative à une personne physique identifiée ou
                  identifiable, directement ou indirectement, par référence à un ou plusieurs éléments qui lui sont propres.
                  Nous pouvons collecter des données à caractère personnel auprès de vous, ou en recevoir de votre part, via
                  nos sites Internet, questionnaires, applications, appareils, pages consacrées aux produits ou marques de
                  GYD Tech sur les réseaux sociaux ou par tout autre moyen.<br/>
                  Le caractère obligatoire ou facultatif de renseigner vos données personnelles vous est signalé lors de la
                  collecte par un astérisque. Si vous ne souhaitez pas fournir les renseignements considérés comme
                  indispensables, vous ne pourrez pas accéder à certains services ou fonctionnalités de notre site internet
                  et de notre application.<br/>
                  Certaines des données que nous vous demandons nous sont indispensables pour les raisons suivantes :
                  L’exécution du contrat conclu avec nous (Ex : vous livrer des marchandises achetées sur notre site) ;
                  La fourniture du service demandé ;<br/>
                  Le respect des obligations légales (Ex : Editer une facture).<br/>
                  <br/>

                  Situations de collecte de vos données personnelles et finalités
                  <br/>

                  Vous trouverez ci-dessous les informations détaillées sur les différentes situations où vos données
                  personnelles sont collectées et leurs finalités.
                  <br/><br/>

                  1. Création et gestion d’un compte
                  <br/>

                  Données Collectées<br/>
                  Finalités<br/>
                  Les données à caractère personnel peuvent comprendre :
                  <br/><br/>

                  Nom et prénom ;<br/>
                  Sexe ;<br/>
                  Adresse de messagerie ;<br/>
                  Numéro de téléphone ;<br/>
                  <br/>

                  Gérer vos commandes ;<br/>
                  Gérer les opérations promotionnelles auxquels vous participez ;<br/>
                  Répondre à vos demandes de renseignement ;<br/>
                  Vous proposer un programme de fidélité ;<br/>
                  Vous permettre de gérer vos préférences ;<br/>
                  Vous adresser des communications commerciales ;<br/>
                  Vous proposer des services personnalisés ;<br/>
                  Contrôler et améliorer notre site Internet et notre application ;<br/>

                  <br/>
                  2 - Achats et gestion des commandes
                  <br/>

                  Données Collectées<br/>
                  Finalités<br/>
                  Les données à caractère personnel peuvent comprendre :<br/>
                  <br/>

                  Nom et prénom ;<br/>
                  Adresse de messagerie ;<br/>
                  Adresse postale (livraison et facturation) ;<br/>
                  Numéro de téléphone ;<br/>
                  Historique des achats.<br/>

                  <br/>
                  Nous utilisons ces données afin de :<br/>
                  Vous contacter pour finaliser votre commande non terminée ;<br/>
                  Vous informer de la disponibilité d’un produit ;<br/>
                  Traiter et assurer le suivi de votre commande ;<br/>
                  Gérer le paiement de votre commande avec nos prestataires de service de paiement ;<br/>
                  Gérer tout contact que vous avez avec nous concernant votre commande ;<br/>
                  Protéger les transactions contre la fraude.<br/>
                  Evaluer la satisfaction ;<br/>
                  Gérer tout litige lié à un achat ;<br/>
                  Réaliser des statistiques.<br/>

                  <br/>
                  3 – Utilisation de l’application GYD Beauty

                  <br/>
                  Données Collectées
                  Finalités<br/>
                  Les données à caractère personnel peuvent comprendre :<br/>

                  <br/>
                  Nom et prénom ;<br/>
                  Adresse de messagerie ;<br/>
                  Numéro de téléphone ;<br/>
                  Age<br/>
                  Photos de vos cheveux<br/>
                  Nous utilisons ces données afin de :<br/>

                  <br/>
                  Analyser vos caractéristiques capillaire et vous recommander les produits et services appropriés (y
                  compris des soins sur mesure) ;<br/>
                  Vous fournir des recommandations ;<br/>
                  Améliorer constamment nos produits, nos logiciels et faire avancer la recherche et l’innovation ;<br/>
                  Réaliser des statistiques<br/>
                  <br/>

                  Nous nous engageons à ne pas utiliser vos données personnelles à d’autres fins que celles prévues. De
                  plus, si, à tout moment, vous souhaitez que nous arrêtions d’utiliser vos informations aux fins détaillées
                  ci-dessus, vous pouvez nous contacter librement dans les conditions définies aux présentes.<br/>

                  <br/><br/>
                  4. Base juridique
                  <br/>
                  Le traitement de vos données repose sur :<br/>
                  - Votre consentement.<br/>
                  - Notre intérêt légitime à améliorer nos services et prévenir la fraude.<br/>
                  L’amélioration de nos produits et services,<br/>
                  La prévention de la fraude,<br/>
                  La sécurisation de nos outils,<br/>
                  - L'exécution d'un contrat, par exemple pour traiter vos commandes.<br/>
                  - Le respect des obligations légales.<br/>

                  Destinataires des données personnelles<br/>
                  Dans le respect des finalités pour lesquelles les données personnelles ont été initialement collectées
                  et/ou pour lesquelles vous auriez expressément consenti par la suite, les informations vous concernant
                  peuvent être transmises :<br/>
                  1-Aux sociétés du Groupe GYD Tech afin de nous conformer à nos obligations légales, d’empêcher la fraude
                  et/ou de sécuriser nos outils, d’améliorer nos produits et services, à des fins de prospection
                  commerciale.<br/>

                  En fonction des finalités pour lesquelles vos données ont été collectées, et uniquement si cela est
                  nécessaire, certaines de vos données à caractère personnel peuvent être accessibles aux entités du Groupe
                  GYD Tech pour vous fournir les services demandés.<br/>

                  Nous pouvons également partager vos données à caractère personnel avec les scientifiques de la division
                  Recherche & Innovation de GYD Tech à des fins de recherche et d’innovation.
                  <br/>
                  Nous ne communiquons vos données à caractère personnel à des fins de prospection commerciale qu’avec votre
                  consentement. Dans ce cadre, vos données sont traitées par l’entité de groupe GYD Tech, qui agit en
                  qualité de responsable du traitement, et sont soumises à ses conditions générales et sa politique de
                  protection des données à caractère personnel. Nous vous recommandons de vérifier attentivement leurs
                  informations avant de consentir à la communication de vos données au profit de ce tiers.<br/>
                  Si cela est permis, nous pouvons réaliser des statistiques basées sur vos caractéristiques et adapter nos
                  communications.<br/>

                  <br/>
                  2 - A des prestataires de services de confiance.
                  <br/>
                  Nous faisons appel à des tiers de confiance pour réaliser un ensemble d’opérations et tâches commerciales
                  en notre nom. Nous ne leur fournissons que les informations dont ils ont besoin pour réaliser le service
                  et leur demandons de ne pas utiliser vos données à caractère personnel pour d’autres finalités. Nous
                  mettons toujours tout en œuvre pour s’assurer que tous ces tiers avec lesquels nous travaillons préservent
                  la confidentialité et la sécurité de vos données.<br/>
                  Voici une liste non exhaustive des services nécessitant un traitement de vos données personnelles que nous
                  pouvons être amené à demander à nos prestataires :<br/>
                  A fournir des services numériques et de commerce électronique, tels des activités de veille sur les
                  réseaux sociaux, des programmes de fidélité, la gestion des identités, la gestion des notations et
                  commentaires, la gestion de la relation client (CRM), l’analyse d’audience (le web analytics), les moteurs
                  de recherche et les outils de création de contenu généré par les utilisateurs ;
                  A livrer un produit ;<br/>
                  A fournir des services informatiques, tels que des services d’hébergement, des services de maintenance et
                  de support technique pour nos bases de données ainsi que pour nos applications qui peuvent contenir des
                  données vous concernant ;
                  A vérifier vos informations lorsque cela est requis pour conclure un contrat avec vous ;
                  A nous aider sur le service à la clientèle
                  A améliorer notre cosmétovigilance.
                  <br/><br/>

                  3 - A des fins de sécurité ou d’application de la législation :
                  Dans certaines circonstances, nous pouvons être tenus de communiquer vos données personnelles en réponse à
                  des demandes valides émanant d’autorités publiques, y compris pour satisfaire les exigences de sécurité
                  nationale ou d’application de la législation.
                  <br/>
                  Transfert de données personnelles hors Espace Economique Européen (EEE)
                  Les données personnelles collectées peuvent être (occasionnellement) transférées à des tiers sélectionnés,
                  qui peuvent se situer en dehors de l’Espace Économique Européen (« EEE ») dans le cadre des services qui
                  vous sont proposés à travers nos sites Web et applications mobiles/Internet. A titre d’exemple, cette
                  situation peut survenir si l’un de nos serveurs se situe dans un pays en dehors de l’EEE ou si l’un de nos
                  prestataires de services se situe dans un pays en dehors de l’EEE.
                  Un tel transfert ne pourra être effectué par GYD Tech qu’en parfait respect des dispositions légales et
                  réglementaires en la matière, et notamment de la Loi Informatique et Liberté du 6 janvier 1978 modifiée et
                  de la réglementation européenne applicable.
                  Lesdits tiers n’utiliseront vos informations personnelles à aucune fin autre que celles que nous avons
                  convenues avec ces derniers et qui vous ont été indiquée. GYD Tech demande auxdits tiers de mettre en
                  œuvre des niveaux de protection suffisants pour préserver la confidentialité et la protection de vos
                  informations personnelles.
                  Nous respectons vos informations personnelles et nous prendrons, par conséquent, des mesures pour nous
                  assurer que vos droits au respect de la vie privée continuent d’être protégés si nous transférons ainsi
                  vos informations en dehors de l’EEE. De plus, si vous utilisez nos services lorsque vous vous trouvez en
                  dehors de l’EEE, vos informations peuvent être transférées en dehors de l’EEE afin de vous fournir lesdits
                  services.<br/>
                  Sous réserve des dispositions de la présente Charte de données personnelles, nous ne divulguerons aucune
                  information personnellement identifiable sans votre autorisation, à moins que nous y soyons légalement
                  habilités ou tenus (par exemple, si nous y sommes tenus de par une procédure judiciaire ou dans le cadre
                  de réquisitions ou interceptions légales).<br/>
                  Nous tenons à vous assurer que nous n’utiliserons vos informations à aucune desdites fins si vous avez
                  indiqué que vous ne souhaitez pas que nous utilisions ainsi vos informations lorsque vous nous les avez
                  soumis, ou ultérieurement.<br/><br/>


                  Conservation de vos données personnelles<br/>
                  Nous conservons vos données à caractère personnel uniquement le temps nécessaire à la réalisation de la
                  finalité pour laquelle nous détenons ces données, afin de répondre à vos besoins ou pour remplir nos
                  obligations légales.<br/>
                  Pour établir la durée de conservation de vos données, nous appliquons les critères suivants :<br/><br/>
                  Cas<br/>
                  Durée<br/>
                  Achat de produits<br/>

                  <br/>
                  Pendant toute la durée de notre relation contractuelle.

                  <br/>
                  Création et gestion de compte
                  <br/>

                  Jusqu’à ce que vous nous demandiez de procéder à leur suppression ou à l’issue d’une période d’inactivité
                  <br/>
                  Nous sommes susceptibles de conserver certaines données à caractère personnel afin de remplir nos
                  obligations légales ou réglementaires, et afin de nous permettre d’exercer nos droits (par ex., déposer un
                  recours devant tout tribunal) ou à des fins statistiques ou historiques.
                  Lorsque nous n’avons plus besoin d’utiliser vos données à caractère personnel, nous les effaçons de nos
                  systèmes et de nos fichiers ou procédons à leur anonymisation afin qu’elles ne permettent plus de vous
                  identifier.<br/>

                  <br/>
                  Spécificité des cookies
                  <br/>

                  Un cookie est un petit fichier de données qu’un site Web, lorsqu’il est consulté par un utilisateur,
                  demande à votre navigateur de stocker sur votre appareil afin de mémoriser des informations vous
                  concernant, telles que vos préférences linguistiques ou vos informations de connexion. Ces cookies sont
                  définis par nous et appelés cookies internes. Nous pouvons également utiliser des cookies tiers, qui
                  proviennent d’un domaine différent de celui de notre site que vous consultez, pour nos efforts
                  publicitaires et marketing, ainsi que pour comprendre votre navigation.

                  <br/><br/>
                  Plus concrètement, nous utilisons des cookies et d’autres traceurs selon les finalités suivantes :
                  <br/><br/>

                  Assister la navigation ;<br/>
                  Accompagner la création de compte, et l’ouverture d’une session ;<br/>
                  Analyser l’utilisation de nos produits, services ou applications ;<br/>
                  Participer à nos efforts de promotion et de marketing (y compris la publicité comportementale)
                  <br/>

                  Nous analysons régulièrement à l’aide de notre outil d’analyse des cookies sur ce site afin de maintenir
                  une liste à jour.

                  <br/><br/>
                  Nous classons les cookies dans les catégories suivantes :

                  <br/>
                  Cookies strictement nécessaires<br/>
                  Cookies de performances<br/>
                  Cookies de fonctionnalité<br/>
                  Cookies publicitaires<br/>

                  <br/>
                  Vous pouvez choisir de vous désinscrire de chaque catégorie de cookies (à l’exception des cookies
                  strictement nécessaires) en cliquant sur le bouton « Paramètres des cookies » au bas de la page.
                  <br/>
                  <br/>
                  Mesures de sécurité<br/>
                  Nous avons mis en place des mesures de sécurité afin de protéger au mieux vos données personnelles contre
                  la destruction accidentelle ou illicite, la perte accidentelle, l’altération, la diffusion ou l’accès non
                  autorisé à vos données. Ces mesures de sécurité sont par ailleurs renforcées dès lors que nous traitons
                  des données considérées comme sensibles. La sécurisation de vos données étant une priorité, nous nous
                  engageons à respecter les standards de sécurité conformément à la réglementation. Nous possédons une
                  gestion stricte des accès à vos données. Ainsi, seul le personnel, dont les fonctions supposent
                  l’utilisation de vos données, est autorisé à consulter les données personnelles que vous nous avez
                  confiées. Dans le cas où nous avons fait appel à un sous-traitant pour traiter des données personnelles
                  pour notre compte, nous nous assurons que celui-ci présente des garanties suffisantes quant à la mise en
                  œuvre de mesures de sécurité techniques et organisationnelles appropriées de manière à ce que le
                  traitement réponde aux exigences du RGPD et garantisse la protection de vos droits.

                  <br/><br/>
                  Liens vers des sites tiers
                  <br/>
                  Ce site peut occasionnellement contenir des liens vers les sites Internet appartenant à nos réseaux,
                  annonceurs et affiliés partenaires. Si vous suivez un lien vers l’un quelconque de ces sites Internet,
                  veuillez noter que ces sites disposent de leurs propres politiques de protection des données à caractère
                  personnel et que nous ne sommes pas responsables concernant ces politiques. Nous vous invitons à prendre
                  connaissance des termes de ces politiques avant d’envoyer toute donnée à caractère personnel à ces sites
                  Internet.<br/><br/>
                  Réseaux sociaux
                  <br/>
                  Ce site peut permettre aux utilisateurs de mettre en ligne leur propre contenu. Nous vous rappelons que
                  tout contenu transmis à l’un des réseaux sociaux que nous utilisons peut-être accessible au public. Ainsi,
                  nous vous invitons à faire preuve de prudence en ce qui concerne la communication de certaines données à
                  caractère personnel telles que des données financières ou une adresse. Nous déclinons toute responsabilité
                  concernant toutes mesures prises par des tiers dans l’hypothèse où vous posteriez des données à caractère
                  personnel sur l’un de nos réseaux sociaux, et nous vous recommandons de ne pas communiquer ces
                  informations.<br/>

                  <br/>
                  Vos droits<br/>
                  Conformément à la loi « Informatique et Liberté » en vigueur et à la règlementation Européenne concernant
                  la protection des données à caractère personnel, vous disposez des droits suivants :<br/>
                  droit d’accès,<br/>
                  droit de rectification,<br/>
                  droit à l’effacement,<br/>
                  droit à la portabilité,<br/>
                  droit d’opposition pour motif légitime,<br/>
                  droit à la limitation du traitement,<br/>
                  droit de retirer votre consentement le cas échéant,<br/>
                  droit de définir des directives relatives au sort de vos données personnelles en cas de décès.
                  <br/>
                  <br/>
                  Ces demandes s’exercent par courrier électronique à l’adresse support@gydtech.io ou par courrier postal à
                  l’attention de GYD Tech, accompagné d’une copie d’un titre d’identité à l’adresse suivante :
                  Nous nous engageons à répondre à la demande dans un délai maximal d’un mois après la réception. Si votre
                  droit ne peut être exercé, Nous vous informerons des raisons dans un délai d’un mois maximum. De plus,
                  tout message vous étant adressé comporte une faculté (notamment par clic sur un lien hypertexte) de
                  s’opposer au traitement ultérieur de ses données à des fins commerciales. Enfin, il est rappelé que vous
                  disposez du droit d’introduire une réclamation auprès d’une autorité de contrôle et notamment auprès de la
                  CNIL (https://www.cnil.fr/fr/plaintes).<br/>
                  Modifications apportées à la présente Charte des données personnelles
                  Nous pourrons périodiquement apporter des modifications à la présente Charte de données personnelles. Si
                  nous apportons toute modification importante à la présente Charte de données personnelles et à la manière
                  dont nous utilisons vos données personnelles, nous afficherons lesdites modifications sur la présente page
                  et nous nous efforcerons de vous signaler toute modification importante. Nous vous invitons à consulter
                  régulièrement cette Charte des données personnelles.<br/>

                </div>
            }
          </div>}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
