import axios from 'axios';

const apiUrl = 'https://api.iux1.com/'

export const upload = async(image)=> {
  const formData = new FormData();
  formData.append('image', image, image.name || 'captured.png');

  return axios.post(`${apiUrl}/api-v2/picture/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
