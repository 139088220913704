import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import './welcome.css';
import SlideToUnlock from '../slide2unlock/slide2unlock';
import ResponsiveDialog from "../popupWelcome/popupWelcome";
import VideoIntro from "../../widgets/videoIntro/videoIntro";
import {titleNormalized} from "../../select";

export default function Welcome({data, handleChangeAdvisor, handleReady}) {
  const [openDialog, setOpenDialog] = useState(true);
  const [seeAdvisor, setSeeAdvisor] = useState(false);
  const [advisor, setAdvisor] = useState('');
  const [showVid, setShowVid] = useState(false);

  const getAppName = (app) => {
    switch (app) {
      case 'chatbot-hair': return getTextApp("chatbot_hair");
      case 'chatbot-hair-ybera': return 'Confidences';
      case 'chatbot-beauty-skin': return 'Beauty     ';
      case 'chatbot-skin': return 'Skin       ';
      default: return 'Confidences';
    }
  }

  const unlock = () => {
    setSeeAdvisor(true);
  };

  const handleVid = (ad) => {
    setAdvisor(ad);
    if(titleNormalized === 'chatbot-hair-ybera')
      setShowVid(true);
    else{
      handleReady(true)
    }
    setTimeout(() => {
      setOpenDialog(false);
    }, 500)
  };

  const handleClose = () => {
    handleChangeAdvisor(advisor);
    setShowVid(false);
  };


  return (
      <React.Fragment>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullScreen style={{width: '100%'}}>
          {/* Contenu du dialogue */}
          {!seeAdvisor && <DialogContent
              sx={{
                backgroundImage: "linear-gradient(to bottom, transparent 35%, #E1C5FF 85%, #DCC5FF 100%), url('/assets/images/background/backgroundGirl.png')",
                backgroundSize: '110%',
                backgroundPosition: 'top center',
                backgroundRepeat: 'no-repeat',
                fontFamily: 'customFont',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '10% 0', // Ajout du padding en haut
                textAlign: 'center',
                width: '100%',
                height: '100%',
                maxWidth: '480px',
                margin: 'auto'
              }}
          >
            <Box sx={{width: '67%', fontWeight: 'bold'}}>
              LE SOIN PERSONNALISÉ,<br></br> BASÉ SUR L'IA
            </Box>
            <Box sx={{fontWeight: '300', marginTop: 'auto', fontSize: '21px'}}>Bienvenue chez, <b>{getAppName(titleNormalized)}   </b>
              <SlideToUnlock unlocked={unlock}></SlideToUnlock>
            </Box>

          </DialogContent>}

          {seeAdvisor && <DialogContent>
            <ResponsiveDialog handleChangeAdvisor={handleVid} data={data}></ResponsiveDialog>
          </DialogContent>}
        </Dialog>
        {showVid && <VideoIntro handleCloseVid={handleClose} handleReady={handleReady}></VideoIntro>}
      </React.Fragment>
    );
}
