import React, { useEffect, useState } from 'react'
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import './App.css';
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import { getProfile, uploadPictureD_id, createfollowingChat, createOrganization, getHairDresser, startChat } from "./api/service";
import { appLanguage, titleNormalized } from "./select";
import { Langage, AppName } from "./language";
import { getSummary } from './api/service-hair.js';
import { resetLocalStorageKeys } from './utils/helper.js';
import Welcome from './components/welcome/welcome.jsx';

function App() {

  const selections = {
    "chatbot-hair-ybera": {
      english: ["first_name", "email", "gender"],
      french: ["prenom", "email", "genre"],
    },
    "chatbot-skin": {
      english: ["first_name", "email", "gender"],
      french: ["prenom", "email", "genre"],
    },
    "chatbot-beauty-skin": {
      english: ["first_name", "email", "gender"],
      french: ["prenom", "email", "genre"],
    },
  };

  const [idOrganization, setIdOrganization] = useState(null);
  const [advisor, setAdvisor] = useState('');
  const [data, setData] = useState(null);
  const [chatReady, setChatReady] = useState(false);

  const handleChangeAdvisor = async (advisor) => {
    if (!localStorage.getItem('followingCactusId')) {
      setAdvisor(advisor);
      localStorage.setItem('advisor', advisor);
      let res = await createfollowingChat({ galien_id: idOrganization, chat_id: null, advisor });
      localStorage.setItem('followingCactusId', res.id);
    }
  }

  const handleReady = (flag) => {
    setChatReady(flag)
  }

  const getText = (key) => {
    return Langage[key][appLanguage];
  }
  const getTextApp = (key) => {
    return AppName[key][appLanguage];
  }

  const getAppName = (app) => {
    switch (app) {
      case 'chatbot-hair': return getTextApp("chatbot_hair");
      case 'chatbot-hair-ybera': return 'Confidences - Le salon';
      case 'chatbot-beauty-skin': return 'Beauty';
      case 'chatbot-skin': return 'Skin';
      default: return 'Cactus';
    }
  }

  useEffect(() => {

    const fetchData = async (id) => {
      try {
        let data = await getHairDresser(localStorage.getItem('organizationId'));
        if (!data || !data.length || data.length === 1) {
          let res = await createfollowingChat({ galien_id: id, chat_id: null, advisor: ((!data || !data.length) ? null : data[0].lastname) });
          localStorage.setItem('followingCactusId', res.id);
          localStorage.setItem('advisor', advisor);
        }
        setData(data);
      } catch (error) {
        console.error('Error fetching hairdresser data:', error);
      }
    }

    const fetchData2 = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let id = urlParams.get('id')
      let advisor = urlParams.get('advisor');
      let data
      if (!id)
        id = titleNormalized.includes('ybera') ? '2094429' : '2085475';
      try {
        const number = parseInt(id, 10);
        data = await createOrganization({ galien_user_id: number, app: window.location.hostname.split('.')[0], group_id: 4 })
        localStorage.setItem('organizationId', data.id);
        setIdOrganization(id)

        if (advisor) {
          let res = await createfollowingChat({ galien_id: id, chat_id: null, advisor });
          localStorage.setItem('followingCactusId', res.id);
          localStorage.setItem('advisor', advisor);
        }
        else if (!advisor) {
          await fetchData(id)
        }

      } catch (error) {
        console.error(error)
      }

    }

    fetchData2()
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        let fromStart = false
        const toRemove = ['chatIdHair', 'followingCactusId', 'advisor', 'chatIdFace']
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        fromStart = urlParams.get('Start') === '1';

        if (fromStart) {
          toRemove.push('chatIdInfos')
        }
        resetLocalStorageKeys(toRemove)
        const getIdChat = async () => {
          try {
            const selection = selections[titleNormalized][appLanguage];
            const data = await startChat('infos', { lang: appLanguage.slice(0, 2) }, { selection });
            if (data.success) {
              localStorage.setItem('chatIdInfos', data.id);
            }
            console.log('chat initialised');
          } catch (error) {
            console.error(error);
          }
        };

        try {
          if (localStorage.getItem('chatIdInfos')) {
            const result = await getSummary(localStorage.getItem('chatIdInfos'));
            if (result.summary) {
              config.state.user = result.summary;
            }
            else {
              await getIdChat();
            }
          }
          else {
            await getIdChat();
          }

        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  let id;
  if (window.location.href.includes('id=')) {
    id = window.location.href.split('id=')[1].split('&')[0];
  } else {
    id = titleNormalized.includes('ybera') ? '2094429' : '2085475';
  }

  useEffect(() => {

    const getAvatar = async (id) => {
      localStorage.removeItem('avatar');
      localStorage.removeItem('video_intro');
      localStorage.removeItem('ai_video_duration');
      localStorage.removeItem('ai_text');
      localStorage.removeItem('mute');
      if (id) {
        try {
          const profile = await getProfile(id);
          if (profile) {
            if (profile.infos && profile.infos.ai_setting) {
              localStorage.setItem('avatar', profile.infos.ai_setting.ai_avatar);
              localStorage.setItem('video_intro', profile.infos.ai_setting.ai_video);
              localStorage.setItem('video_text', profile.infos.ai_setting.ai_text);
              localStorage.setItem('ai_video_duration', (((profile.infos.ai_setting.ai_video_duration || 0) * 1000) - 500).toString());

              const link = document.createElement("link");
              link.rel = "prefetch";
              link.as = "video";
              link.type = "video/mp4";
              link.href = localStorage.getItem('video_intro');
              document.body.append(link);
            }
            if (profile.name) {
              document.querySelector('.react-chatbot-kit-chat-header').innerHTML = profile.name;
            }
          }
        } catch {

        }
      }
    }
    getAvatar(id);

  }, [id]);


  return (
    <div className="App">
      <Welcome
        data={data}
        handleChangeAdvisor={handleChangeAdvisor}
        advisor={advisor}
        handleReady={handleReady}>
      </Welcome>
      {chatReady && <Chatbot
        config={config}
        headerText={getAppName(titleNormalized)}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        placeholderText={getText('placeholder')}
        validator={(msg) => msg.length > 0}
      />}
      <div className={'chatId'}>
        {localStorage.getItem('chatIdInfos')}
      </div>
    </div>
  );
}

export default App;


