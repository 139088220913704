import React, { useState, useEffect } from 'react'
import { associateCustomer2Salon, createContact, createSalonChatCustomer, discussWithChat, getToken, startChat, updatefollowingChat } from '../api/service.js'
import { appLanguage, titleNormalized } from "../select";
import { Langage, LangageError, LangageHair } from "../language";
import Speech from 'speak-tts'
import { concatenateWithHyphen, replaceDashesAndUnderscores } from '../utils/helper.js';
import { getConditionerAdvice, getIdForm, getRoutine, getShampooAdvice, getSummary, getSupplementAdvice } from '../api/service-hair.js';
import { sendEmail, sendRecommendationEmail } from '../api/api-conseil-hair.js';
import { registerCustomer } from '../utils/utils.js';
import { createClientMessage } from "react-chatbot-kit";
import { Language } from '@mui/icons-material';
import { hairPropertiesJson } from "../utils/possibilities";
import { getRandomText } from '../assets/gydSkin.js';
import { recommendProducts, recommendProductsByNeeds, recommendProductsByText } from '../api/service-beauty-skin.js';
import UploadImage from '../widgets/Skin/uploadImage/uploadImage.jsx';





const speech = new Speech()
if (speech.hasBrowserSupport()) {
  // console.log("speech synthesis supported")
  speech.init({
    'volume': 1,
    'lang': (appLanguage === 'french' ? 'fr-FR' : 'en-US'),
    'rate': 1,
    'pitch': 1,
    'voice': (appLanguage === 'french' ? 'Google français' : ((titleNormalized === 'new-pharma') ? 'Microsoft Mark - English (United States)' : 'Google US English')),
    'splitSentences': true,
    'listeners': {
      'onvoiceschanged': (voices) => {
        // console.log("Event voiceschanged", voices)
      }
    }
  }).then((data) => {
  }).catch(e => {
    console.error("An error occured while initializing : ", e)
  })
}

const getText = (key) => {

  if (titleNormalized.includes('hair')) {
    return LangageHair[key][appLanguage];
  }
  return Langage[key][appLanguage];
}

const handleVideo = (src, text, time) => {
  let video = document.getElementById('vid');
  // let video_text_span = document.getElementById('vid_text_span');
  let container_video = document.getElementById('container_vid')

  video.src = src
  // video_text_span.style.animation = 'none';
  // void video_text_span.offsetWidth; /* trigger reflow */
  // video_text_span.style.animation = null;
  // video_text_span.textContent = text
  container_video.style.transform = 'translateY(0)';
  video.play();
  setTimeout(() => {
    container_video.style.transform = 'translateY(1000%)';
  }, time)
}
const handleTTS = (message, delay = 500) => {
  if (!localStorage.getItem('MUTE_TTS') || !JSON.parse(localStorage.getItem('MUTE_TTS'))) {
    setTimeout(() => {
      let msg
      message.payload.choices ? msg = message.message + message.payload.choices.map(item => item.text).join(', ') : msg = message.message
      speech.speak({ text: replaceDashesAndUnderscores(msg) });
    }, delay);
  }
}





const ActionProvider = ({ createChatBotMessage, setState, children }) => {


  const states = children.props.children.props.state

  const [step, setStep] = useState(0)

  const input = document.getElementsByClassName("react-chatbot-kit-chat-input")[0] || {};

  const createContactBrevo = async (diag, diag_infos, recommendation, recommendation_lien, listId, langue, advisor, orderId) => {


    const contact = {
      email: diag_infos.summary.email,
      firstName: diag_infos.summary.prenom,
      lastName: diag_infos.summary.nom,
      recommendation_lien,
      listId,
      langue,
      texte_conseils: recommendation,
      etat_cuirchevelu: diag.summary.cuir_chevelu ? diag.summary.cuir_chevelu : '', // initialmeent rendait une liste
      etat_cheveux: diag.summary.etat ? diag.summary.etat : '',  // initialmeent rendait une liste
      longueur_cheveux: diag.summary.longueur,
      type_cheveux: diag.summary.type,
      DATE_DERNIER_DIAG_GYDBEAUTYCOIF: new Date(),
      TEXTE_RECO_SHAMP: null,
      TEXTE_RECO_APRESSHAMP: null,
      TEXTE_RECO_COMPLALI: null,
      COIFFEUR_NOM: advisor,
      SOURCE: titleNormalized.includes('ybera') ? 'CONFIDENCE' : 'AUTRE',
      OBJECTIF_CAPILLAIRE_1: concatenateWithHyphen(diag.summary.objectifs_soin) + '-' + concatenateWithHyphen(diag.summary.objectifs_beaute),
      URL_DIAG: `https://dashboard.gydtech.io/espace-client/diag/${orderId}?token=${states.token}`,
      URL_PERSO: `https://dashboard.gydtech.io/authentication?token=${states.token}&redirect_route=espace-client`
    }

    if (titleNormalized.includes('hair')) {
      const form = {
        etat_cuirchevelu: contact.etat_cuirchevelu,
        etat_cheveux: contact.etat_cheveux,
        longueur_cheveux: contact.longueur_cheveux,
        type_cheveux: contact.type_cheveux
      }

      try {
        const [shampooAdvice, conditionerAdvice, supplementAdvice] = await Promise.all([
          getShampooAdvice({ language: langue.slice(0, 2), form }),
          getConditionerAdvice({ language: langue.slice(0, 2), form }),
          getSupplementAdvice({ language: langue.slice(0, 2), form })
        ]);

        contact.TEXTE_RECO_SHAMP = shampooAdvice['shampoo-advice'];
        contact.TEXTE_RECO_APRESSHAMP = conditionerAdvice['conditioner-advice'];
        contact.TEXTE_RECO_COMPLALI = supplementAdvice['supplement-advice'];
      } catch (error) {
        // Gérer les erreurs ici
        console.error("Une erreur s'est produite lors de la récupération des conseils:", error);
      }
    }

    await createContact(contact)
  }

  const generateRoutine = async (id) => {
    let result
    try {
      result = await getSummary(id)

      if (!result.summary)
        throw new Error("Un problème est survenu lors de la recommendation personalisée")

      const obj = {
        "language": (appLanguage.slice(0, 2)),
        "summary": result.summary
      }
      result = await getRoutine(obj, 'face')
      result.response = result['conseils']
      result.success = true
      result.status = 'FINISHED'

    } catch (error) {
      result.success = false
      result.response = error.message
    }
    finally {
      return result
    }
  }

  const generateRoutineScores = async () => {
    let result
    try {
      let analysisObject = {};
      Object.keys(states.scores).map(s => { analysisObject[s.replace('traitement', '')] = states.scores[s] });

      const obj = {
        "language": (appLanguage.slice(0, 2)),
        "summary": analysisObject
      }
      result = await getRoutine(obj, 'face')
      result.response = result['care-routine']
      result.success = true
      result.status = 'FINISHED'

    } catch (error) {
      result.success = false
      result.response = error.message
    }
    finally {
      return result
    }
  }

  const ask4Selfie = async (id) => {

    let result = {}
    result.success = true
    if (titleNormalized.includes('hair')) {
      result.response = getText(('take_final_selfie'))
    } else {
      result.response = getText(('start_experience'))
    }
    result.status = 'FINISHED'
    result.options = {
      "widget": "selfieOrChat",
      "delay": (titleNormalized === 'chatbot-hair-ybera' ? 7000 : 2000)
    }

    return result
  }



  const buildResult = (success, response, status) => {

    let result = {}
    result.success = success
    result.response = response
    result.status = status

    return result

  }



  let lstModule
  if (titleNormalized === 'chatbot-hair-ybera') {
    lstModule = [discussWithChat]
  }
  if (titleNormalized === 'chatbot-beautySkin') {
    lstModule = [discussWithChat]
  }

  let app; // todo remove after salon
  const pharmaId = (window.location.href.includes('id=') ? window.location.href.split('id=')[1].split('&')[0] : '2085475');
  if (pharmaId === '2087398') {
    app = 'jess';
  } else {
    app = titleNormalized;
  }

  const next = async (message, tmpStep) => {

    switch (titleNormalized) {
      case 'chatbot-hair-ybera': nextYbera(message, tmpStep);
        break;
      case 'chatbot-beauty-skin': nextBeautySkin(message, tmpStep);
        break;
      case 'chatbot-skin': nextSkin(message, tmpStep);
        break;
      default: return nextYbera(message, tmpStep);
    }

  };

  const nextBeautySkin = async (message, tmpStep) => {
    let result, id;
    const searchBy = localStorage.getItem("prodOrNeed")

    let num = tmpStep ? tmpStep : step  // si un step est fourni a la fonction next il doit primer sur l etat step

    try {
      loadingPoints()

      switch (num) {
        case 0:
          if (states.user) {
            if (states.user.genre === 'homme') {
              document.body.style.background = "url('/assets/images/background/man_back.gif') no-repeat fixed";
              document.querySelector('.react-chatbot-kit-chat-input').style.background = 'linear-gradient(to right, #d5efff61, #d5efffad)';
            } else {
              document.body.style.background = "url('/assets/images/background/woman_back.gif') no-repeat fixed";
            }
            const msg = `${Langage.hello[appLanguage]} ${states.user.prenom || ''} ${Langage.hello_again[appLanguage]}`
            result = buildResult(true, msg, "FINISHED")
            break;
          } else {
            id = localStorage.getItem("chatIdInfos")
            const obj = { "role": 'user', "content": message }
            result = await discussWithChat(id, obj)
            break;
          }
        case 1:
          const msg2 = "Souhaitez-vous que je recherche des produits en fonction de la description du produit que vous recherchez ou en fonction de vos besoins spécifiques ?"
          result = buildResult(true, `${msg2}`, "FINISHED")
          result.options = { "widget": "prodOrNeed" }
          break;
        case 2:
            let msg1;
            if (searchBy === 'Produits') {
              msg1 = "Quelles sont vos attentes concernant le produit que vous recherchez ?";
            } else if (searchBy === 'Besoins') {
              msg1 = "Pouvez-vous me décrire les particularités de votre peau ainsi que ses besoins spécifiques ?";
            }
            const msg = getRandomText(appLanguage)
            createBotMessage(msg1)
            result = buildResult(true, `Exemple de message: ${msg}`, "FINISHED")
            break;
        case 3:
          let res;
          if (searchBy === 'Produits') {
            res = await recommendProducts(message, {});
          } else if (searchBy === 'Besoins') {
            res = await recommendProductsByNeeds(message, {});
          }
          
          
          const obj = {
            //json_data: JSON.stringify({ ...states.json_data, selected_products: checkedProductsClean.join(','), checkedSoins: checkedSoins.join(',') })
            organization_id: localStorage.getItem("organizationId"),
            customer_id: states.user.customer_id,
            json_data: JSON.stringify({
              ...states.json_data,
              advisor: localStorage.getItem('advisor'),
              diag: res
            })
          };
          res = await createSalonChatCustomer(obj);
          console.log(res);
          
          result = buildResult(true, "Vous allez être redirigé automatiquement vers votre résultat.", "FINISHED")
          setTimeout(() => {
            window.open(`https://skin.gydtech.io/analysis/${res.id}`, "_blank");
          }, 3000);
          break;
        default:
        // code block
      }
      removeLoadingPoints();

      if (!result || !result.success)
        throw new Error(LangageError['chat_error'][appLanguage]);

      if (result.status === 'FINISHED') {
        setStep(prevStep => prevStep + 1)
      }

      if (result.status === 'RUNNING' || result.status === 'FINISHED' || result.status === 'CANCELLED') {
        if (result && result.expected) {
          if (!titleNormalized.includes('ybera') && typeof result.expected === 'object' && Object.keys(result.expected).length) {
            Object.keys(result.expected).forEach(ex => {
              const enums = (result.expected[ex].items ? (result.expected[ex].items.enum || result.expected[ex].items) : result.expected[ex].enum);
              if (enums && Array.isArray(enums) && enums.length > 1 && enums.length <= 5) {
                result.options = { "widget": "choices", "payload": { "choices": enums } };
              } else if (ex === 'age') {
                result.options = { "widget": "choices", "payload": { "choices": ['age'] } };
              }
            })
          }
        }
      }

      createBotMessage(result.response, result.options)
      if (result.status === 'FINISHED' && num === 0) {
        const diag_infos = await getSummary(localStorage.getItem("chatIdInfos"))
        const person = diag_infos.summary
        await updatefollowingChat({ step: true, num_step: 2, info_user: JSON.stringify(person) }, localStorage.getItem("followingCactusId"))
        const customer_id = await registerCustomer(person.prenom, person.nom, person.email, person.age)
        setState(prevState => ({
          ...prevState,
          user: { ...person, customer_id: customer_id }
        }))
        await associateCustomer2Salon(localStorage.getItem("organizationId"), customer_id)
        const token = await getToken(customer_id)
        setState(prevState => ({
          ...prevState,
          token: token.token
        }))
        if (titleNormalized) {
          next(getText('start_diag'), 1)
          return
        }
      }



    } catch (error) {
      createBotMessage(error.toString());
      removeLoadingPoints();
    }
    setTimeout(() => {
      const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild
      lastChild.scrollIntoView()
    }, 3000)
  };

  const nextSkin = async (message, tmpStep) => {
    let result, id;

    let num = tmpStep ? tmpStep : step  // si un step est fourni a la fonction next il doit primer sur l etat step

    try {
      loadingPoints()

      switch (num) {
        case 0:
          if (states.user) {
            if (states.user.genre === 'homme') {
              document.body.style.background = "url('/assets/images/background/man_back.gif') no-repeat fixed";
              document.querySelector('.react-chatbot-kit-chat-input').style.background = 'linear-gradient(to right, #d5efff61, #d5efffad)';
            } else {
              document.body.style.background = "url('/assets/images/background/woman_back.gif') no-repeat fixed";
            }
            const msg = `${Langage.hello[appLanguage]} ${states.user.prenom || ''} ${Langage.hello_again[appLanguage]}`
            result = buildResult(true, msg, "FINISHED")
            break;
          } else {
            id = localStorage.getItem("chatIdInfos")
            const obj = { "role": 'user', "content": message }
            result = await discussWithChat(id, obj)
            break;
          }
        case 1:
          const msg = `
         Pour prendre votre selfie, suivez ces recommandations :

          1. Retirez vos lunettes si vous en avez 
          2. Dégagez vos cheveux de votre visage
          3. Regardez droit devant vous avec une expression neutre

      `;


          result = buildResult(true, msg, "FINISHED")
          result.options = { widget: "uploadSkinImage" }
          break;
        case 2:
          
          result = buildResult(true, "Vous allez être redirigé automatiquement vers votre résultat.", "FINISHED")
          setTimeout(() => {
            window.open(`https://skin.gydtech.io/analysis/${res.id}`, "_blank");
          }, 3000);

          break;
        default:
        // code block
      }
      removeLoadingPoints();

      if (!result || !result.success)
        throw new Error(LangageError['chat_error'][appLanguage]);

      if (result.status === 'FINISHED') {
        setStep(prevStep => prevStep + 1)
      }

      if (result.status === 'RUNNING' || result.status === 'FINISHED' || result.status === 'CANCELLED') {
        if (result && result.expected) {
          if (titleNormalized.includes('ybera') && hairPropertiesJson[((result.expected || { title: '' }).title || result.expected).toLowerCase()]) {
            result.options = { "widget": "choices", "payload": { type: result.expected.type, "choices": hairPropertiesJson[((result.expected || { title: '' }).title || result.expected).toLowerCase()], qt: ((result.expected || { title: '' }).title || result.expected) } };
          } else if (!titleNormalized.includes('ybera') && typeof result.expected === 'object' && Object.keys(result.expected).length) {
            Object.keys(result.expected).forEach(ex => {
              const enums = (result.expected[ex].items ? (result.expected[ex].items.enum || result.expected[ex].items) : result.expected[ex].enum);
              if (enums && Array.isArray(enums) && enums.length > 1 && enums.length <= 5) {
                result.options = { "widget": "choices", "payload": { "choices": enums } };
              } else if (ex === 'age') {
                result.options = { "widget": "choices", "payload": { "choices": ['age'] } };
              }
            })
          }
        }
      }

      createBotMessage(result.response, result.options)
      if (result.status === 'FINISHED' && num === 0) {
        const diag_infos = await getSummary(localStorage.getItem("chatIdInfos"))
        const person = diag_infos.summary
        await updatefollowingChat({ step: true, num_step: 2, info_user: JSON.stringify(person) }, localStorage.getItem("followingCactusId"))
        const customer_id = await registerCustomer(person.prenom, person.nom, person.email, person.age)
        setState(prevState => ({
          ...prevState,
          user: { ...person, customer_id: customer_id }
        }))
        await associateCustomer2Salon(localStorage.getItem("organizationId"), customer_id)
        const token = await getToken(customer_id)
        setState(prevState => ({
          ...prevState,
          token: token.token
        }))
        if (titleNormalized) {
          next(getText('start_diag'), 1)
          return
        }
      }



    } catch (error) {
      createBotMessage(error.toString());
      removeLoadingPoints();
    }
    setTimeout(() => {
      const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild
      lastChild.scrollIntoView()
    }, 3000)
  };

  const nextYbera = async (message, tmpStep) => {
    let result, id, obj;

    let num = tmpStep ? tmpStep : step  // si un step est fourni a la fonction next il doit primer sur l etat step

    if (num > lstModule.length - 1)
      return;

    try {
      loadingPoints()

      switch (num) {
        case 0:
          if (states.user) {
            if (states.user.genre === 'homme') {
              document.body.style.background = "url('/assets/images/background/man_back.gif') no-repeat fixed";
              document.querySelector('.react-chatbot-kit-chat-input').style.background = 'linear-gradient(to right, #d5efff61, #d5efffad)';
            } else {
              document.body.style.background = "url('/assets/images/background/woman_back.gif') no-repeat fixed";
            }
            const msg = `${Langage.hello[appLanguage]} ${states.user.prenom || ''} ${Langage.hello_again[appLanguage]}`
            result = buildResult(true, msg, "FINISHED")
            break;
          } else {
            id = localStorage.getItem("chatIdInfos")
            obj = { "role": 'user', "content": message }
            result = await lstModule[num](id, obj)
            break;
          }
        case 1:
          if (titleNormalized === 'chatbot-hair-ybera') {
            id = localStorage.getItem("chatIdHair")
            if (!id) {
              result = await startChat('hair', { lang: appLanguage.slice(0, 2) })
              id = result.id
              localStorage.setItem("chatIdHair", result.id)
            }
            document.querySelector('.chatId').innerText = localStorage.getItem("chatIdHair");
            obj = { "role": 'user', "content": message }
            result = await lstModule[num](id, obj, '/v3')
          } else {
            result = await lstModule[num](id)
          }
          break;
        case 2:
          if (titleNormalized === 'chatbot') {
            id = localStorage.getItem("chatIdFace")
            if (!id) {
              result = await startChat('general', { lang: appLanguage.slice(0, 2) })
              id = result.id
              localStorage.setItem("chatIdFace", result.id)
            }
            obj = { "role": 'user', "content": message }
            result = await lstModule[num](id, obj);
          } else {
            result = await lstModule[num](id)
          }
          break;
        case 3:
          if (titleNormalized === 'chatbot-hair-ybera') {
            id = localStorage.getItem("chatIdHair")
            result = await lstModule[num](id)
            document.getElementsByClassName("react-chatbot-kit-chat-input-container")[0].style.display = 'none'
            document.getElementsByClassName("react-chatbot-kit-chat-message-container")[0].style.cssText = 'height: calc(100vh - 120px) !important;'
          } else if (titleNormalized.includes('cnen')) {
            result = await lstModule[num]()
          } else {
            result = await lstModule[num]()
          }
          break;
        case 4:
          id = localStorage.getItem("chatIdFace")
          result = await lstModule[num](id)
          break;
        default:
        // code block
      }
      removeLoadingPoints();

      if (!result || !result.success)
        throw new Error(LangageError['chat_error'][appLanguage]);

      if (result.status === 'FINISHED') {
        setStep(prevStep => prevStep + 1)
      }

      if (result.status === 'RUNNING' || result.status === 'FINISHED' || result.status === 'CANCELLED') {
        if (result && result.expected) {
          if (titleNormalized.includes('ybera') && hairPropertiesJson[((result.expected || { title: '' }).title || result.expected).toLowerCase()]) {
            result.options = { "widget": "choices", "payload": { type: result.expected.type, "choices": hairPropertiesJson[((result.expected || { title: '' }).title || result.expected).toLowerCase()], qt: ((result.expected || { title: '' }).title || result.expected) } };
          } else if (!titleNormalized.includes('ybera') && typeof result.expected === 'object' && Object.keys(result.expected).length) {
            Object.keys(result.expected).forEach(ex => {
              const enums = (result.expected[ex].items ? (result.expected[ex].items.enum || result.expected[ex].items) : result.expected[ex].enum);
              if (enums && Array.isArray(enums) && enums.length > 1 && enums.length <= 5) {
                result.options = { "widget": "choices", "payload": { "choices": enums } };
              } else if (ex === 'age') {
                result.options = { "widget": "choices", "payload": { "choices": ['age'] } };
              }
            })
          }
        }

        if (titleNormalized === 'chatbot-hair-ybera' && num === 3) {
          createBotMessage(getText('see_conseils'), result.options);
          setTimeout(() => {
            Object.keys(result.response).forEach((k, index) => {
              setTimeout(() => {
                result.response[k] = `\n${k}:\n\n ${result.response[k]}`;
                createBotMessage(result.response[k], result.options)
              }, 7000 * index);
            });
          }, 5000)
        } else {
          createBotMessage(result.response, result.options)
        }
      }

      if (result.status === 'FINISHED' && num === 0) {
        const diag_infos = await getSummary(localStorage.getItem("chatIdInfos"))
        const person = diag_infos.summary
        console.log(localStorage.getItem("followingCactusId"));
        await updatefollowingChat({ step: true, num_step: 2, info_user: JSON.stringify(person) }, localStorage.getItem("followingCactusId"))
        const customer_id = await registerCustomer(person.prenom, person.nom, person.email, person.age)
        setState(prevState => ({
          ...prevState,
          user: { ...person, customer_id: customer_id }
        }))
        await associateCustomer2Salon(localStorage.getItem("organizationId"), customer_id)
        const token = await getToken(customer_id)
        setState(prevState => ({
          ...prevState,
          token: token.token
        }))
        if (titleNormalized) {
          next(getText('start_diag'), 1)
          return
        }
      }

      if (result.status === 'FINISHED' && lstModule.length === num + 1) {

        let listId, templateId;
        if (appLanguage === 'french') {
          listId = 13
          templateId = (titleNormalized === 'chatbot-hair-ybera' ? 68 : 61)
        }
        if (appLanguage === 'english') {
          listId = 14
          templateId = 56
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let pharmaId = urlParams.get('id')

        if (!pharmaId) {
          if (titleNormalized === 'chatbot-hair-ybera')
            pharmaId = '2094429'
          else pharmaId = '2085475'
        }

        let id;
        if (titleNormalized === 'chatbot-hair-ybera') {
          id = localStorage.getItem("chatIdHair")
        } else {
          id = localStorage.getItem("chatIdFace")
        }

        const diag_infos = await getSummary(localStorage.getItem("chatIdInfos"))
        let url = `https://democactus.gydtech.io?mail=${diag_infos.summary.email}&token=${states.token}&pharmaId=${pharmaId}&lang=${appLanguage}`;
        if (!titleNormalized.includes('cnen')) {
          const data = await getIdForm(id)
          const advisor = localStorage.getItem("advisor")
          const diag = await getSummary(id)
          const followingId = localStorage.getItem('followingCactusId')
          url = `https://dashboard.gydtech.io/espace-client/panier?firstName=${diag_infos.summary.prenom}&mail=${diag_infos.summary.email}&token=${states.token}${(titleNormalized === 'chatbot' ? '' : `&idQuestionner=${data.idform}`)}&pharmaId=${pharmaId}&advisor=${advisor}&followingId=${followingId}&lang=${appLanguage}`

          setState(prevState => ({
            ...prevState,
            json_data: {
              ...prevState.json_data,
              advisor: advisor,
              followingId: followingId,
              diag: diag,
              diag_infos: diag_infos,
              url: url
            }
          }));
          const obj = {
            //json_data: JSON.stringify({ ...states.json_data, selected_products: checkedProductsClean.join(','), checkedSoins: checkedSoins.join(',') })
            organization_id: localStorage.getItem("organizationId"),
            customer_id: states.user.customer_id,
            order_id: id,
            json_data: JSON.stringify({
              ...states.json_data,
              advisor: localStorage.getItem('advisor'),
              followingId: followingId,
              diag: diag,
              diag_infos: diag_infos,
              url: url
            })
          };
          const tmp = await createSalonChatCustomer(obj);
          url += `&orderId=${tmp.id}`

          await createContactBrevo(diag, diag_infos, result.response, url, listId, appLanguage, advisor, tmp.id)
          await updatefollowingChat({ step: true, num_step: 3, chat_id: id }, localStorage.getItem("followingCactusId"))
        }
        const params = {
          first_name: diag_infos.summary.prenom,
          to: diag_infos.summary.email,
          url,
          templateId
        }
        if (titleNormalized === 'chatbot-hair-ybera') {
          await sendEmail([{ "email": diag_infos.summary.email, "name": diag_infos.summary.prenom }], templateId,
            { "PRENOM": diag_infos.summary.prenom, "URL_PERSO": `https://dashboard.gydtech.io/authentication?token=${states.token}` })
        } else {
          await sendRecommendationEmail(result.response, params)
        }
        if (titleNormalized === 'chatbot-hair-ybera') {
          setTimeout(() => {
            createBotMessage('Découvrez le soin recommandé pour vous', { widget: 'oneButton', payload: { url, content: 'Mes soins recommandés', id } });
            setTimeout(() => {
              const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild;
              lastChild.scrollIntoView();
            }, 3500);
          }, Object.keys(result.response).length * 7000)
        }
        else if (titleNormalized === 'chatbot-hair-ybera') {
          createBotMessage(getText('see_selection'), { widget: 'oneButton', payload: { url, content: getText('see_selection') } })
        } else {
          let analysisObject = {};
          Object.keys(states.scores).map(s => { analysisObject[s.replace('traitement', '')] = states.scores[s] });
          analysisObject = JSON.stringify(analysisObject).replaceAll('{', '').replaceAll('}', '').replaceAll('"', '');
          const uid_or_analysis = /*states.uid ? `&uid=${states.uid}` : */`&scores=${analysisObject}`;
          const urlAbbi = `https://skanmyskin.com/selfy-miror/product-selection?register=true${uid_or_analysis}&email=${diag_infos.summary.email}&f_name=${diag_infos.summary.prenom}&l_name=${diag_infos.summary.nom}&language=${appLanguage}`;
          createBotMessage(getText('your_products'), { widget: 'offers', payload: { url: (url + `&scores=${analysisObject}`), urlAbbi } });
        }
      }

      if (result.status === 'FINISHED' && num + 1 === 1) {
        next(null, 1)
        return
      }
      // if (result.status === 'FINISHED' && num + 1 == 2) {
      //   next(null, 2)
      //   return
      // }
      if (result.status === 'FINISHED' && num + 1 == 4) {  // todo why ?
        next(null, 4)
        return
      }
      if (result.status === 'FINISHED' && num === 2 && titleNormalized === 'chatbot') {
        next(null, 3)
        return
      }
      if (result.status === 'FINISHED' && num == 1 && titleNormalized === 'chatbot-hair-ybera') {
        // setState(prevState => ({
        //   ...prevState,
        //   json_data: {...json_data, advisor: localStorage.getItem('advisor')}
        // }))

        next(null, 2)
        return
      }
      // if(result.status === 'FINISHED' && num == 2){
      //   next(null, 3)
      //   return
      // }
    } catch (error) {
      createBotMessage(error.toString());
      removeLoadingPoints();
    }
    setTimeout(() => {
      const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild
      lastChild.scrollIntoView()
    }, 3000)

    // const inputBar = document.querySelector('.react-chatbot-kit-chat-input-container');
    // if(question?.widget === 'multiChoices' || question?.widget === 'yesOrNot' )
    //   inputBar.style.display = "none";
    // else
    //   inputBar.style.display = "block";

    // inputBar.blur();
  };





  const createBotMessage = (msg, options = {}) => {
    input.readOnly = false
    const message = createChatBotMessage(
      msg,
      Object.assign({
        withAvatar: false,
      }, options)
    );
    addMessageToState(message)
  };

  const createUserMessage = (msg, options = {}) => {
    input.readOnly = false
    const message = createClientMessage(
      msg,
      options
    );
    addMessageToState(message)
  };


  // gere le style du chargement lors de l emission du message
  const loadingPoints = () => {
    const loading_point = document.createElement('span');
    const next_point = document.createElement('span');
    loading_point.className = 'loading-points';
    loading_point.append(next_point);
    document.body.append(loading_point);
  }

  const removeLoadingPoints = () => {
    const loading_point = document.querySelectorAll('.loading-points');
    loading_point && loading_point.forEach(e => e.remove());
    // speechToText();
  }

  // tient a jour le state du bot en ajoutant au state le dernier message recu
  const addMessageToState = (message) => {
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { next, createBotMessage, addMessageToState },
        });
      })}
    </div>
  );
};

export default ActionProvider;


