import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import './uploadImage.css';
import { upload } from '../../../api/service-skin';
import { createSalonChatCustomer } from '../../../api/service';

const UploadImage = (props) => {
    const [flagUpload, setFlagUpload] = useState(false);

    const handleCapture = async (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const tmp = { "imgSelfie": [...props.state.imgSelfie, file] };
                props.setState((prev) => {
                    return { ...prev, ...tmp };
                });

                const res = await upload(file);
                if (res.data.success) {
                    setFlagUpload(true);
                    const obj = {
                        //json_data: JSON.stringify({ ...states.json_data, selected_products: checkedProductsClean.join(','), checkedSoins: checkedSoins.join(',') })
                        organization_id: localStorage.getItem("organizationId"),
                        customer_id: states.user.customer_id,
                        json_data: JSON.stringify({
                            ...states.json_data,
                            advisor: localStorage.getItem('advisor'),
                            diag: res.data
                        })
                    };
                    await createSalonChatCustomer(obj);
                    props.actionProvider.next(null);
                }
            }
        }
    };

    return (
        <Box>
            <Button component="label" variant="contained" startIcon={<PhotoCameraIcon />}>
                {"Prendre un selfie"}
                <input
                    hidden
                    accept="image/*,text/plain"
                    type="file"
                    onChange={(e) => {
                        handleCapture(e.target);
                    }}
                />
            </Button>
            {flagUpload && <div className="success-message">Votre image a bien été uploadée</div>}
        </Box>
    );
};

export default UploadImage;
