import React from 'react'
import { Button, Box } from '@mui/material';
import './productOrNeed.css';


const ProductOrNeed = (props) => {
    // const { choices } = questions[props.payload.uid]
    // const { setState } = props

    const choices = ['Produits', 'Besoins']

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {choices.map((item, index) => <Button onClick={() =>  { localStorage.setItem("prodOrNeed", item); props.actionProvider.next()}} key={index} variant="outlined">{item}</Button> )
        }
        </Box>
    )
}

export default ProductOrNeed