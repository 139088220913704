const randomText = [
    "Un soin anti-rides intensif, riche en actifs régénérants et hydratants. Ce soin aidera à réduire visiblement les rides et ridules, tout en améliorant l'élasticité et la fermeté de la peau.",
    "Un soin régulateur et éclatant, conçu pour traiter les problématiques de sensibilité et de régulation de la peau. Sa formule hydratante et anti-taches aide à réduire les rougeurs, à uniformiser le teint et à atténuer les imperfections. Parfait pour apporter confort et luminosité à la peau.",
    "Un soin anti-rides et anti-taches puissant, conçu pour régénérer la peau et atténuer les signes de l'âge. Enrichi en actifs régulateurs, ce soin aidera à lisser les rides, uniformiser le teint et réduire les taches pigmentaires. Il apportera éclat et fermeté à la peau tout en la protégeant des agressions extérieures.",
    "Un soin anti-rides et anti-imperfections intensif, conçu pour cibler les signes visibles du vieillissement cutané. Sa formule régulatrice aide à réduire l'apparence des rides et des imperfections, tout en régulant l'excès de sébum pour une peau plus lisse et plus uniforme.",
    "Un soin hydratant intense pour les peaux sensibles, formulé pour réguler la sensibilité cutanée et apporter un confort immédiat. Ce soin aidera à réduire les rougeurs et les irritations, tout en renforçant la barrière cutanée.",
    "Un soin régulateur et hydratant, idéal pour traiter les problèmes de régulation de sébum et d'hydratation. Ce soin aidera à équilibrer la production de sébum, à hydrater en profondeur et à apaiser les peaux sensibles. Il contribuera également à réduire les imperfections et à améliorer l'éclat de la peau.",
    "Un soin anti-âge global, spécialement conçu pour les hommes de 40 ans et plus. Ce soin régénérant et hydratant aide à atténuer les rides, à réguler la production de sébum, à apaiser les peaux sensibles et à réduire l'apparence des taches et des imperfections. Il laisse la peau plus lumineuse, plus lisse et plus uniforme.",
    "Un soin anti-âge global, enrichi en actifs régénérants et antioxydants, pour lutter contre les rides, les taches pigmentaires et les imperfections. Sa formule hydratante et apaisante convient parfaitement aux peaux sensibles, tout en apportant un éclat naturel et en régulant la production de sébum.",
    "Un soin anti-âge et éclat, conçu pour traiter les rides, les taches pigmentaires et les imperfections. Sa formule riche en actifs régénérants et illuminants aidera à redonner de l'éclat et de la jeunesse à la peau mature.",
    "Un soin régulateur et hydratant, idéal pour les peaux matures en quête d'éclat. Enrichi en actifs régulateurs, il aide à réduire l'apparence des pores dilatés tout en apportant une hydratation intense. Sa texture légère pénètre rapidement pour laisser la peau douce, lisse et éclatante.",
    "Un soin anti-taches et anti-imperfections, enrichi en actifs régulateurs et éclaircissants pour uniformiser le teint et réduire les imperfections. Sa texture légère et non grasse pénètre rapidement pour hydrater la peau en profondeur.",
    "Un soin éclat et anti-taches, conçu pour les peaux matures en quête de luminosité. Enrichi en actifs régénérants et en agents anti-taches, ce soin aide à atténuer les taches pigmentaires tout en boostant l'éclat naturel de la peau. Sa texture légère pénètre rapidement pour une hydratation optimale.",
    "Un soin anti-âge complet, conçu pour réguler la production de sébum, réduire les rides et les imperfections, tout en apportant de l'éclat à la peau. Ce soin hydratant et régénérant aidera à atténuer les taches pigmentaires et à resserrer les pores.",
    "Un soin anti-rides et éclat, enrichi en actifs régénérants et illuminants. Ce soin aidera à lisser les rides, à uniformiser le teint et à redonner de l'éclat à la peau fatiguée.",
    "Un soin anti-rides intensif conçu pour les hommes de 46 ans. Sa formule puissante aide à réduire visiblement les rides et ridules, tout en améliorant l'élasticité de la peau. Ce soin apportera éclat et fermeté à la peau, tout en la protégeant des agressions extérieures.",
    "Un soin ciblé pour traiter les imperfections et les taches, tout en régulant l'excès de sébum. Ce soin aidera à réduire l'apparence des imperfections, à uniformiser le teint et à matifier la peau, tout en la protégeant des agressions extérieures.",
    "Un soin hydratant et régulateur de pores, conçu pour les peaux masculines de 30 ans. Ce soin aidera à hydrater en profondeur, à réguler la production de sébum et à réduire l'apparence des pores dilatés, tout en apportant de l'éclat à la peau.",
    "Un soin hydratant intensif, conçu pour apporter une hydratation optimale à la peau mature. Sa formule riche en actifs hydratants et apaisants aidera à réduire les signes de sensibilité et à améliorer la texture de la peau.",
    "Un soin anti-âge complet, ciblant principalement l'hydratation et la régénération de la peau. Ce soin aidera à réduire les imperfections et à améliorer l'éclat du teint, tout en minimisant l'apparence des pores.",
    "Un soin anti-rides et anti-âge puissant, enrichi en actifs régulateurs de sébum pour une peau éclatante et parfaitement hydratée. Sa texture légère pénètre rapidement pour une action ciblée et efficace.",
    "Un soin régulateur et matifiant, idéal pour traiter les imperfections et les pores dilatés. Sa formule spécifique permettra de réduire l'apparence des imperfections tout en resserrant les pores pour une peau plus lisse et uniforme.",
    "Un soin régulateur et hydratant, conçu pour traiter les problématiques de régulation de sébum et d'hydratation. Ce soin aidera à réduire l'excès de sébum tout en maintenant la peau hydratée et équilibrée. Il est parfait pour les peaux matures en quête d'équilibre et de confort.",
    "Un soin régulateur et anti-taches, conçu pour réguler la production de sébum et atténuer les taches pigmentaires liées à l'âge. Ce soin apportera de l'éclat à la peau tout en la protégeant des agressions extérieures.",
    "Un soin anti-rides et anti-taches, conçu pour régénérer la peau et lui redonner de l'éclat. Ce soin hydratant et régulateur aidera à lisser les rides, à atténuer les taches pigmentaires et à réguler la production de sébum, pour une peau plus uniforme et lumineuse.",
    "Un soin anti-rides et anti-pores dilatés, spécialement conçu pour lisser la peau, réduire l'apparence des rides et resserrer les pores. Sa formule régulatrice aide à équilibrer la production de sébum et à atténuer les imperfections.",
    "Un soin anti-rides et anti-taches, conçu pour régénérer la peau et atténuer les signes de l'âge. Sa formule régulatrice aidera à uniformiser le teint et à réduire les imperfections, tout en hydratant en profondeur.",
    "Un soin hydratant et anti-rides spécialement conçu pour les peaux jeunes. Sa formule légère pénètre rapidement dans la peau pour hydrater en profondeur et lisser les ridules. Enrichi en actifs régénérants, ce soin favorise le renouvellement cellulaire et aide à prévenir l'apparition des premières rides.",
    "Un soin anti-rides et régulateur de sébum, conçu pour lisser les rides et ridules tout en régulant l'excès de sébum. Sa formule hydratante et apaisante convient parfaitement aux peaux sensibles.",
    "Un soin anti-rides et éclat qui cible les premiers signes de l'âge tout en apportant luminosité et vitalité à la peau. Sa texture légère pénètre rapidement pour hydrater en profondeur et lisser les ridules, laissant la peau plus ferme et éclatante.",
    "Un soin anti-âge global, riche en actifs régénérants et anti-rides. Il aide à lisser les rides et ridules, à améliorer la fermeté de la peau et à raviver son éclat naturel. Sa texture confortable pénètre rapidement et laisse la peau douce et revitalisée.",
    "Un soin anti-âge global, conçu pour traiter les rides, la sensibilité et l'hydratation de la peau. Sa formule régulatrice aidera à réduire les rides, à apaiser les peaux sensibles et à hydrater en profondeur.",
    "Un soin anti-rides et hydratant, conçu pour réguler la production de sébum et réduire les imperfections. Ce soin aidera à lisser les rides, à hydrater en profondeur et à améliorer l'éclat de la peau, tout en réduisant les imperfections et en resserrant les pores.",
    "Un soin anti-rides intensif, riche en actifs hydratants et régénérants pour lisser les rides et ridules. Sa formule adaptée aux peaux sensibles apaisera les irritations et renforcera la barrière cutanée. Ce soin apportera confort et souplesse à la peau tout en lui redonnant de l'éclat.",
    "Un soin anti-rides et anti-taches puissant, conçu pour les peaux matures et sensibles. Enrichi en actifs régénérants et éclaircissants, ce soin aide à lisser les rides, estomper les taches pigmentaires et uniformiser le teint. Sa texture riche et nourrissante apporte confort et souplesse à la peau.",
    "Un soin anti-rides et régulateur de sébum, idéal pour les peaux matures sujettes aux rides et à l'excès de sébum. Ce soin aidera à lisser les rides, à réguler la production de sébum et à améliorer la texture de la peau.",
    "Un soin anti-âge global, conçu pour traiter les rides, les taches pigmentaires et les imperfections. Sa formule régulatrice et éclatante aidera à lisser la peau, à atténuer les taches et à réduire les imperfections pour un teint plus uniforme et lumineux.",
    "Un soin anti-rides et anti-taches puissant, conçu pour régénérer la peau en profondeur. Sa formule riche en actifs régénérants et en antioxydants aide à lisser les rides, à estomper les taches et à améliorer l'éclat du teint. Il convient parfaitement aux peaux matures en quête de jeunesse et de luminosité.",
    "Un soin régulateur de l'éclat et de l'hydratation, conçu pour traiter les rides, les taches et les pores dilatés. Ce soin aidera à uniformiser le teint, à réduire les signes de l'âge et à améliorer la texture de la peau.",
    "Un soin régulateur de sébum et anti-âge, conçu pour réguler l'excès de sébum, réduire l'apparence des rides et des pores dilatés. Ce soin apportera de l'éclat à la peau tout en la maintenant hydratée et protégée.",
    "Un soin anti-rides et anti-taches, enrichi en actifs régulateurs de sébum pour une peau éclatante et uniforme. Ce soin hydratant et régénérant aidera à lisser les rides, à estomper les taches pigmentaires et à réguler l'excès de sébum. Il convient parfaitement pour les peaux sensibles et mixtes.",
    "Un soin anti-rides et anti-taches concentré en actifs régénérants et éclaircissants. Sa formule puissante aide à lisser les rides, à estomper les taches pigmentaires et à uniformiser le teint. Parfait pour redonner de l'éclat et de la jeunesse à la peau mature.",
    "Un soin régulateur de pores hautement efficace, conçu pour réduire visiblement l'apparence des pores dilatés. Sa formule ciblée aide à affiner le grain de peau et à réguler la production de sébum, pour une peau plus lisse et uniforme. Idéal pour les peaux matures en quête de perfection.",
    "Un soin anti-taches concentré, enrichi en actifs éclaircissants et régénérants. Ce soin ciblera efficacement les taches pigmentaires liées à l'âge, tout en apportant de l'éclat à la peau. Sa formule douce conviendra parfaitement aux peaux sensibles.",
    "Un soin régulateur et anti-imperfections, conçu pour traiter les problèmes de peau liés à l'excès de sébum et aux imperfections. Sa formule ciblée aidera à réduire l'apparence des rides et des taches tout en apportant de l'éclat à la peau.",
    "Un soin régulateur de sébum et illuminateur, spécialement conçu pour les peaux mixtes à grasses. Enrichi en actifs régulateurs, il aide à réduire l'excès de sébum tout en apportant de l'éclat et de la fraîcheur au teint.",
    "Un soin anti-rides et régulateur de sébum, idéal pour les peaux matures sujettes aux rides et à l'excès de sébum. Ce soin aidera à lisser les rides, à réguler la production de sébum et à améliorer la fermeté de la peau.",
    "Un soin anti-rides intensif, enrichi en actifs régénérants et en antioxydants. Il aide à réduire visiblement les rides et ridules, tout en améliorant la fermeté et l'élasticité de la peau. Sa texture riche et nourrissante apporte confort et souplesse à la peau mature.",
    "Un soin régulateur et éclatant, conçu pour traiter la sensibilité et l'éclat de la peau. Ce soin aidera à réguler la production de sébum, à réduire les taches pigmentaires et à apporter de l'éclat au teint.",
    "Un soin éclat et anti-taches, enrichi en actifs régulateurs de sébum pour une peau matifiée et lumineuse. Ce soin hydratant et régulateur aidera à atténuer les taches pigmentaires tout en apportant de l'éclat au teint. Il convient parfaitement à une peau jeune de 21 ans, en aidant à prévenir l'apparition des premières rides et en régulant l'excès de sébum.",
    "Un soin régulateur de l'excès de sébum, idéal pour traiter les taches pigmentaires et les imperfections liées à l'âge. Ce soin aidera à uniformiser le teint et à réduire l'apparence des pores dilatés.",
    "Un soin anti-rides et anti-taches concentré en actifs régénérants et éclaircissants. Sa texture riche et nourrissante pénètre rapidement pour lisser les rides, uniformiser le teint et atténuer les taches pigmentaires. Il apporte confort et éclat à la peau mature.",
    "Un soin régulateur et anti-imperfections, conçu pour les peaux sensibles et sujettes aux taches et aux pores dilatés. Ce soin aidera à réguler la production de sébum, à atténuer les taches pigmentaires et à resserrer les pores pour une peau plus lisse et uniforme.",
    "Un soin hydratant intense, conçu pour traiter la déshydratation de la peau. Sa formule riche en actifs hydratants aidera à restaurer l'équilibre hydrique de la peau et à réduire les signes de sécheresse et de tiraillement.",
    "Un soin éclat et hydratant, conçu pour apporter luminosité et éclat au teint tout en maintenant une hydratation optimale. Sa formule anti-rides aide à lisser les ridules et à prévenir le vieillissement cutané.",
    "Un soin régulateur de sébum et anti-taches, parfait pour traiter les problématiques de régulation de la production de sébum et d'hyperpigmentation. Sa formule ciblée aidera à uniformiser le teint et à réduire l'apparence des taches brunes, tout en régulant l'excès de sébum pour une peau plus éclatante.",
    "Un soin régulateur et hydratant qui aide à réduire les rides et les imperfections tout en régulant la production de sébum. Sa formule riche en actifs anti-âge et en agents hydratants renforce la barrière cutanée et améliore l'élasticité de la peau.",
    "Un soin anti-rides et régulateur de sébum, spécialement conçu pour traiter les rides et ridules tout en régulant l'excès de sébum. Sa formule hydratante et apaisante convient parfaitement aux peaux sensibles.",
    "Un soin régulateur et éclatant, idéal pour les peaux matures sujettes aux imperfections et aux taches pigmentaires. Ce soin régulera l'excès de sébum, tout en apportant de l'éclat à la peau. Sa formule anti-taches aidera à uniformiser le teint et à atténuer les taches existantes.",
    "Un soin régulateur de sébum, conçu pour réguler l'excès de sébum et réduire l'apparence des pores dilatés. Ce soin aidera à matifier la peau tout en la laissant hydratée et éclatante.",
    "Un soin régulateur et apaisant, conçu pour traiter la sensibilité cutanée et réguler les déséquilibres. Ce soin aidera à réduire les rougeurs, les irritations et à renforcer la barrière cutanée pour une peau plus confortable et apaisée.",
    "Un soin anti-rides et anti-âge intensif, conçu pour réduire visiblement les rides et ridules. Sa formule riche en actifs régénérants et en antioxydants aide à stimuler le renouvellement cellulaire et à améliorer la fermeté de la peau. Il apaise également les peaux sensibles et réactives.",
    "Un soin hydratant et anti-rides spécialement conçu pour les hommes de 23 ans. Sa formule légère pénètre rapidement dans la peau pour hydrater en profondeur et prévenir l'apparition des premières rides. Enrichi en actifs régulateurs, ce soin aidera également à réduire l'excès de sébum et à matifier la peau.",
    "Un soin anti-rides et anti-imperfections, conçu pour répondre aux besoins spécifiques des peaux matures. Enrichi en actifs régénérants et apaisants, ce soin aide à lisser les rides, à réduire les imperfections et à apaiser les peaux sensibles. Il procure un teint éclatant et uniforme.",
    "Un soin régulateur de pores et éclat, parfait pour une peau jeune de 27 ans. Sa texture légère et non grasse aide à réduire l'apparence des pores dilatés tout en apportant de l'éclat au teint. Enrichi en actifs hydratants, il maintient l'hydratation de la peau tout au long de la journée.",
    "Un soin anti-rides et anti-taches puissant, conçu pour traiter les signes de l'âge et uniformiser le teint. Ce soin hydratant et régénérant aidera à lisser les rides, à estomper les taches pigmentaires et à redonner de l'éclat à la peau. Sa formule spécifique conviendra parfaitement à une peau mature et sujette aux imperfections.",
    "Un soin anti-rides et hydratant, conçu pour lisser les ridules et les rides tout en apportant confort et souplesse à la peau. Sa formule riche en actifs régénérants et en antioxydants aide à prévenir le vieillissement cutané et à améliorer l'élasticité de la peau.",
    "Un soin régulateur de sébum et anti-imperfections, conçu pour traiter les problèmes de sensibilité et d'acné chez les femmes de 37 ans. Ce soin aidera à réduire les imperfections, à réguler la production de sébum et à apaiser les rougeurs.",
    "Un soin hydratant intense, conçu pour réguler la sensibilité de la peau et lui redonner de l'éclat. Ce soin cible les rides, les taches et les pores dilatés, tout en apportant une hydratation profonde et durable. Il aidera à réduire les signes de l'âge et à améliorer la texture de la peau.",
    "Un soin éclat et anti-imperfections, enrichi en actifs régulateurs et hydratants. Ce soin ciblera les rides, les taches et les pores dilatés, tout en apportant de l'éclat et de la fermeté à la peau.",
    "Un soin anti-âge global, riche en actifs hydratants et régénérants. Ce soin ciblera les rides et les imperfections, tout en apportant confort et souplesse à la peau. Sa texture légère pénétrera rapidement pour une action en profondeur.",
    "Un soin anti-rides et anti-âge puissant, conçu pour réduire visiblement les rides et ridules. Sa formule régénérante stimule le renouvellement cellulaire et améliore l'élasticité de la peau. Il aide également à réguler la production de sébum, à hydrater en profondeur et à réduire l'apparence des pores.",
    "Un soin hydratant et apaisant, conçu pour réguler la sensibilité de la peau et lui redonner de l'éclat. Sa formule riche en actifs hydratants et apaisants aide à réduire les rougeurs et les irritations, tout en renforçant la barrière cutanée. Parfait pour les peaux sensibles et déshydratées.",
    "Un soin anti-rides et hydratant, conçu pour réduire l'apparence des rides et ridules tout en améliorant l'élasticité de la peau. Ce soin apportera également une hydratation en profondeur pour une peau plus lisse et plus ferme.",
    "Un soin anti-âge global, conçu pour traiter les rides, les taches pigmentaires et les imperfections. Sa formule riche en actifs régénérants et éclaircissants aidera à redonner de l'éclat à la peau tout en la protégeant des agressions extérieures.",
    "Un soin anti-rides concentré en actifs régénérants et hydratants, idéal pour prévenir et atténuer les premières rides. Sa formule riche en antioxydants aidera à lisser la peau, à stimuler le renouvellement cellulaire et à améliorer l'élasticité cutanée.",
    "Un soin régulateur de l'éclat et des taches, conçu pour traiter les problématiques de régulation de la peau et d'hyperpigmentation. Ce soin aidera à uniformiser le teint et à réduire l'apparence des taches tout en apportant de l'éclat à la peau.",
    "Un soin régulateur de sébum et anti-taches, idéal pour les peaux matures sujettes aux rides et aux taches pigmentaires. Sa formule riche en actifs régulateurs aide à équilibrer la production de sébum et à uniformiser le teint. Il apporte également une hydratation en profondeur pour une peau plus éclatante et revitalisée.",
    "Un soin anti-rides intensif, enrichi en actifs régénérants et hydratants. Sa texture riche pénètre en profondeur pour lisser les rides et ridules, tout en stimulant le renouvellement cellulaire. Il aide à redonner fermeté et élasticité à la peau mature, pour un teint plus jeune et éclatant.",
    "Un soin hydratant et apaisant, conçu pour les peaux sensibles et matures. Ce soin aidera à réduire les rougeurs, les irritations et les sensations d'inconfort, tout en apportant une hydratation en profondeur pour une peau plus douce et plus souple.",
    "Un soin anti-rides et anti-taches concentré en actifs régénérants et éclaircissants. Sa texture légère pénètre rapidement dans la peau pour une action ciblée et efficace. Il aide à lisser les rides, à estomper les taches pigmentaires et à uniformiser le teint.",
    "Un soin hydratant et régulateur, conçu pour apaiser les peaux sensibles et réduire les rougeurs. Sa formule riche en actifs hydratants et apaisants aide à atténuer les rides et à uniformiser le teint. Parfait pour une peau déshydratée et sujette aux irritations.",
    "Un soin anti-rides et anti-taches concentré en actifs régénérants et éclaircissants. Sa formule riche en antioxydants aide à réduire l'apparence des rides et des taches pigmentaires, tout en améliorant la fermeté et l'élasticité de la peau.",
    "Un soin régulateur de sébum, idéal pour les peaux matures sujettes aux brillances. Ce soin aidera à rééquilibrer la production de sébum, tout en apportant confort et apaisement à la peau sensible. Il contribuera également à atténuer les imperfections et à resserrer les pores.",
    "Un soin éclat et hydratant, conçu pour révéler l'éclat naturel de la peau tout en la maintenant parfaitement hydratée. Ce soin aidera à atténuer les taches pigmentaires et à lisser les rides, tout en régulant la production de sébum pour une peau équilibrée.",
    "Un soin anti-rides et anti-pores dilatés, conçu pour traiter efficacement les signes de l'âge et améliorer la texture de la peau. Ce soin régénérant et lissant aidera à réduire l'apparence des rides et des pores, tout en apportant fermeté et éclat à la peau.",
    "Un soin anti-rides et anti-taches, enrichi en actifs régénérants et éclaircissants pour une peau visiblement plus lisse et lumineuse. Sa texture légère pénètre rapidement et agit en profondeur pour atténuer les signes de l'âge et uniformiser le teint.",
    "Un soin anti-rides et anti-âge puissant, conçu pour réduire visiblement les rides et ridules. Sa formule régénérante et raffermissante aide à améliorer l'élasticité de la peau et à restaurer son éclat naturel. Il convient parfaitement aux peaux sensibles et matures.",
    "Un soin régulateur et anti-rides, conçu pour apporter éclat et fermeté à la peau. Sa formule légère et non grasse pénètre rapidement pour hydrater en profondeur et lisser les rides. Parfait pour une peau mature, ce soin aide à atténuer les rides et à réguler la production de sébum pour un teint plus uniforme.",
    "Un soin anti-âge global, riche en actifs régénérants et hydratants. Sa formule cible les rides, les taches pigmentaires et les pores dilatés, tout en apportant de l'éclat à la peau. Il convient parfaitement aux peaux matures en quête de jeunesse et de luminosité.",
    "Un soin anti-rides intensif, conçu pour réduire visiblement les rides et ridules tout en améliorant l'élasticité de la peau. Sa formule riche en actifs anti-âge aidera à raffermir la peau et à restaurer son éclat naturel.",
    "Un sérum anti-rides concentré en actifs régénérants et en antioxydants, idéal pour prévenir et atténuer les signes de l'âge. Ce sérum aidera à lisser les rides, à améliorer l'élasticité de la peau et à raviver son éclat naturel.",
    "Un soin anti-âge complet, conçu pour traiter les rides, les taches, les imperfections et la sensibilité de la peau. Ce soin apportera hydratation, éclat et régulation tout en réduisant l'apparence des pores.",
    "Un soin hydratant et anti-rides enrichi en actifs régénérants pour lisser les ridules et améliorer la fermeté de la peau. Sa texture légère pénètre rapidement et apporte confort et éclat au visage. Parfait pour une peau mature en quête de jeunesse et de vitalité.",
    "Un soin anti-rides et hydratant intensif, conçu pour réduire l'apparence des rides et des ridules tout en apportant confort et souplesse à la peau. Sa formule régulatrice aidera également à équilibrer la production de sébum et à atténuer les rougeurs.",
    "Un soin anti-âge global, riche en actifs hydratants et régénérants. Ce soin ciblera les rides, la sensibilité et l'hydratation de la peau, tout en apportant de l'éclat et en resserrant les pores.",
    "Un soin régulateur et anti-âge, conçu pour traiter les rides, les taches pigmentaires et les imperfections. Sa formule puissante aidera à réguler la production de sébum, à estomper les rides et à uniformiser le teint.",
    "Un soin régulateur de sébum et anti-âge, conçu pour traiter les problèmes de sensibilité, d'éclat et d'hydratation. Ce soin aidera à réguler la production de sébum, à réduire les rides et à améliorer l'éclat de la peau, tout en la maintenant hydratée.",
    "Un soin anti-rides et anti-imperfections, enrichi en actifs régénérants et purifiants. Il aide à lisser les rides, à réduire les imperfections et à améliorer l'éclat de la peau.",
    "Un soin régulateur de l'hydratation et des taches, conçu pour les peaux sensibles et matures. Ce soin aidera à réguler la production de sébum, à atténuer les taches pigmentaires et à hydrater en profondeur pour une peau plus éclatante et uniforme.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Sa formule régulatrice aide à éliminer l'excès de sébum et à purifier la peau en profondeur, tout en apaisant les irritations. Idéal pour préparer la peau aux soins suivants.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laissera la peau propre, fraîche et confortable.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau, la laissant propre et fraîche.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs. Il convient parfaitement pour une utilisation quotidienne.",
    "Un nettoyant doux et hydratant, formulé pour éliminer les impuretés tout en préservant l'équilibre naturel de la peau. Idéal pour une peau mature, il aide à prévenir le vieillissement cutané en laissant la peau propre et fraîche.",
    "Un nettoyant doux et hydratant, spécialement formulé pour éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule régulatrice aide à contrôler l'excès de sébum et à réduire l'apparence des pores, tout en laissant la peau fraîche et éclatante.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laisse la peau propre, fraîche et lumineuse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour réguler l'excès de sébum et purifier la peau en profondeur. Sa formule adaptée aux peaux sensibles permet de nettoyer en douceur sans agresser la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux rougeurs. Sa formule apaisante aide à réduire l'inflammation et à calmer les irritations, tout en nettoyant en profondeur pour éliminer les impuretés et les résidus de maquillage.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs.",
    "Un nettoyant doux et hydratant spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau. Il est idéal pour préparer la peau avant l'application des soins anti-âge.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau, la laissant propre et fraîche.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour réguler l'excès de sébum et apaiser les peaux sensibles. Ce nettoyant aidera à purifier la peau en profondeur tout en la laissant douce et confortable.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux taches. Sa formule délicate nettoie en profondeur tout en apaisant et en unifiant le teint.",
    "Un nettoyant doux et hydratant, spécialement formulé pour réguler l'excès de sébum et apaiser les peaux sensibles. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Il est idéal pour préparer la peau à recevoir les soins anti-âge.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau, la laissant propre et fraîche.",
    "Un nettoyant doux et hydratant, formulé pour éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Idéal pour nettoyer en profondeur les pores et préparer la peau à recevoir les soins suivants.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, formulé pour éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Idéal pour les peaux sensibles et sujettes à la sécheresse, ce nettoyant aidera à prévenir les signes de vieillissement prématuré.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Il aide à éliminer les impuretés tout en apaisant et en protégeant la peau.",
    "Un nettoyant doux et hydratant spécialement formulé pour les peaux sensibles, afin de nettoyer en profondeur tout en apaisant les irritations. Sa formule délicate respecte l'équilibre naturel de la peau et aide à réduire les rougeurs et les sensations d'inconfort.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laisse la peau propre, fraîche et lumineuse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux rides. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau, la laissant douce et souple.",
    "Un nettoyant doux et hydratant, formulé pour éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Idéal pour une peau mature et sujette aux rides, ce nettoyant aidera à prévenir le vieillissement cutané et à maintenir l'éclat du teint.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux taches. Sa formule délicate aide à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs. Idéal pour préparer la peau avant l'application des soins anti-rides et anti-taches.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule anti-âge contribue à réduire l'apparence des rides et des taches, laissant la peau plus lumineuse et plus lisse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs. Il convient parfaitement pour une utilisation quotidienne.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Il aide à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs. Idéal pour préparer la peau avant l'application des soins.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule délicate convient parfaitement aux peaux sensibles et matures.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Il est idéal pour préparer la peau à recevoir les soins anti-âge.",
    "Un nettoyant doux et purifiant, spécialement formulé pour éliminer les impuretés et l'excès de sébum tout en respectant la sensibilité de la peau. Ce nettoyant aidera à prévenir l'apparition de nouvelles imperfections et à réduire l'apparence des pores dilatés.",
    "Un nettoyant doux et hydratant, formulé pour éliminer les impuretés tout en préservant l'équilibre naturel de la peau. Sa formule délicate convient parfaitement aux peaux sensibles et réactives.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule délicate convient parfaitement aux peaux sensibles et fragiles.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs. Il convient parfaitement à une peau jeune de 21 ans, en aidant à maintenir l'équilibre naturel de la peau sans l'assécher.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule délicate convient parfaitement aux peaux sensibles et fragiles.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en protégeant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule régulatrice aide à contrôler l'excès de sébum et à réduire l'apparence des pores dilatés.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Il est idéal pour préparer la peau à recevoir les soins anti-âge.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Il élimine en douceur les impuretés et les résidus de maquillage tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, formulé pour éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Idéal pour une peau sensible et sujette aux imperfections.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule anti-rides contribuera à lisser la peau et à réduire l'apparence des rides et ridules.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs et les inflammations.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laisse la peau propre, fraîche et lumineuse.",
    "Un nettoyant doux et hydratant, formulé pour éliminer les impuretés tout en préservant l'équilibre naturel de la peau. Idéal pour les peaux sensibles et sujettes à l'acné, ce nettoyant aidera à purifier la peau en profondeur sans l'assécher.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laisse la peau propre, fraîche et lumineuse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux taches. Sa formule délicate nettoie en profondeur tout en apaisant les irritations et en unifiant le teint. Idéal pour préparer la peau avant l'application des soins.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs. Sa formule régulatrice contribuera également à contrôler l'excès de sébum et à réduire l'apparence des pores.",
    "Un nettoyant doux et hydratant, spécialement formulé pour réguler l'excès de sébum et réduire les imperfections. Sa formule non irritante convient parfaitement aux peaux sensibles. Il aide à purifier la peau en profondeur tout en maintenant son hydratation naturelle.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et délicates. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en protégeant la peau. Il convient parfaitement pour une routine quotidienne de nettoyage du visage.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en protégeant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laissera la peau propre, fraîche et lumineuse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laisse la peau propre, fraîche et lumineuse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles. Il élimine en douceur les impuretés et les résidus de maquillage tout en apaisant et en protégeant la peau. Idéal pour préparer la peau aux soins suivants.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes à la sécheresse. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs et les inflammations.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule anti-rides contribue à lisser la peau et à atténuer les signes de l'âge.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laisse la peau propre, fraîche et lumineuse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau, la laissant propre et confortable.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Il aide à éliminer les impuretés tout en apaisant et en nourrissant la peau. Sa formule non irritante convient parfaitement aux peaux sujettes aux rougeurs et aux irritations.",
    "Un nettoyant doux et hydratant, spécialement formulé pour réguler l'excès de sébum et purifier la peau. Il aide à réduire les imperfections et les pores dilatés, tout en apportant de l'éclat au teint. Idéal pour une peau mixte à grasse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau, la laissant propre et fraîche.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau, la laissant douce et souple.",
    "Un nettoyant doux et hydratant, spécialement formulé pour réguler l'excès de sébum et apaiser les peaux sensibles. Ce nettoyant aidera à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux rides. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau, la laissant propre et confortable.",
    "Un nettoyant doux et hydratant, spécialement formulé pour éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule délicate convient parfaitement aux peaux sensibles et aide à réguler l'excès de sébum.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau. Sa formule nourrissante laisse la peau propre, fraîche et lumineuse.",
    "Un nettoyant doux et hydratant, spécialement formulé pour réguler l'excès de sébum et réduire l'apparence des pores. Idéal pour une peau mature et sujette aux imperfections, ce nettoyant aide à prévenir l'accumulation de sébum et de saleté, tout en laissant la peau propre et fraîche.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux matures. Il aide à éliminer les impuretés tout en préservant l'hydratation naturelle de la peau, la laissant propre et fraîche.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant la peau et en réduisant les rougeurs et les inflammations.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour réguler l'excès de sébum et purifier la peau. Sa formule délicate convient parfaitement aux peaux sensibles et aide à réduire l'apparence des pores. Idéal pour une peau éclatante et équilibrée.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et sujettes aux imperfections. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en hydratant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles. Il aide à éliminer les impuretés tout en apaisant et en protégeant la peau.",
    "Un nettoyant doux et hydratant, spécialement formulé pour les peaux sensibles et matures. Ce nettoyant aidera à éliminer les impuretés tout en apaisant et en nourrissant la peau.",
    "Un complément de traitement intensif pour resserrer les pores dilatés et réduire l'apparence des taches pigmentaires. Ce complément ciblé aidera à affiner le grain de peau et à uniformiser le teint pour une peau plus lisse et lumineuse.",
    "Un complément de traitement pour les taches pigmentaires et les imperfections cutanées. Ce produit ciblera spécifiquement les zones concernées, aidant à uniformiser le teint et à réduire les imperfections.",
    "Un complément de soin ciblé pour traiter les rides, les pores dilatés et les signes de l'âge. Sa formule concentrée en actifs anti-rides et en agents lissants aide à réduire visiblement les rides, à resserrer les pores et à améliorer la fermeté de la peau. Idéal pour compléter la routine de soin et préserver la jeunesse de la peau.",
    "En complément du traitement anti-rides et anti-taches, un sérum hydratant et apaisant sera idéal pour traiter la sensibilité cutanée et maintenir une hydratation optimale. Ce sérum léger pénétrera rapidement dans la peau pour apaiser les irritations, réduire les rougeurs et renforcer la barrière cutanée. Il complètera efficacement le traitement global pour une peau visiblement plus belle et éclatante.",
    "Un complément de soin hydratant et régulateur, idéal pour compléter la routine de soins quotidiens. Il apporte une hydratation intense tout en aidant à réduire l'apparence des pores dilatés et des taches pigmentaires, pour une peau éclatante et uniforme.",
    "Un complément de traitement pour les peaux sensibles, ciblant spécifiquement l'hydratation et la régulation de la sensibilité cutanée. Ce complément aidera à renforcer l'efficacité des autres produits utilisés, tout en apportant une protection supplémentaire contre les agressions extérieures.",
    "Un complément de traitement anti-taches, conçu pour cibler spécifiquement les taches pigmentaires. Ce complément concentré en actifs éclaircissants aidera à atténuer les taches existantes et à prévenir l'apparition de nouvelles taches. Il est recommandé de l'utiliser en complément des autres soins pour une action ciblée et efficace.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs anti-âge et en agents régulateurs de sébum. Ce complément cible spécifiquement les pores dilatés pour affiner le grain de peau et réduire l'excès de sébum. Il complète l'action du soin en apportant une action ciblée pour une peau plus nette et plus uniforme.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs éclaircissants et unifiant pour traiter spécifiquement les taches pigmentaires et les imperfections. Sa texture légère pénètre rapidement dans la peau pour un effet ciblé et une action renforcée contre les signes de l'âge.",
    "En complément du nettoyant et du soin, un sérum hydratant et apaisant sera idéal pour renforcer l'hydratation de la peau et calmer les rougeurs. Sa texture légère pénétrera rapidement pour laisser la peau douce et confortable.",
    "Un complément de soin anti-âge, spécialement conçu pour les peaux matures en manque d'éclat. Sa formule concentrée en actifs anti-taches et anti-rides aide à uniformiser le teint et à lisser la peau. En complément du soin quotidien, il contribue à réduire visiblement les signes de l'âge pour une peau plus lumineuse et éclatante.",
    "Un complément de soin perfecteur de peau, spécialement conçu pour réduire l'apparence des pores dilatés et lisser le grain de peau. Sa formule légère et matifiante convient parfaitement aux peaux jeunes en quête d'éclat et de perfection.",
    "Un complément de traitement pour les peaux sensibles et sujettes aux imperfections. Ce produit ciblé agit spécifiquement sur les pores dilatés et les imperfections cutanées, aidant à affiner le grain de peau et à réduire l'apparence des boutons. Sa formule régulatrice et matifiante convient parfaitement aux peaux matures en quête d'un teint plus uniforme.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs anti-âge et en agents régulateurs. Ce sérum ciblera spécifiquement les rides, les imperfections et les pores dilatés, pour une peau visiblement plus lisse, plus uniforme et plus éclatante.",
    "Un complément de traitement pour réguler l'excès de sébum et resserrer les pores. Ce produit aidera à matifier la peau tout en réduisant l'apparence des pores dilatés pour un teint plus lisse et uniforme.",
    "En complément du traitement anti-rides, un sérum régulateur de sébum peut être utilisé pour contrôler l'excès de sébum et réduire les brillances. Ce sérum aidera à affiner le grain de peau et à minimiser l'apparence des pores, tout en maintenant une hydratation équilibrée.",
    "En complément, un sérum hydratant et éclatant peut être utilisé pour renforcer l'hydratation de la peau et lui redonner de l'éclat. Ce sérum léger pénétrera rapidement dans la peau pour la nourrir en profondeur et lui apporter un teint lumineux et uniforme.",
    "En complément, un sérum anti-taches et anti-âge pourrait être bénéfique pour traiter les premiers signes de l'âge et les taches pigmentaires. Ce sérum aidera à uniformiser le teint et à réduire l'apparence des rides, tout en apportant de la luminosité à la peau.",
    "Un complément de soin ciblé pour traiter les taches pigmentaires et les imperfections cutanées. Ce produit aidera à uniformiser le teint et à réduire l'apparence des pores, laissant la peau plus lisse et éclatante.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs anti-imperfections sera idéal pour cibler spécifiquement les problèmes de peau liés aux imperfections. Ce sérum aidera à réduire l'apparence des imperfections et à uniformiser le teint.",
    "Un complément de soin idéal pour traiter les imperfections cutanées et affiner le grain de peau. Sa formule non comédogène aide à réduire l'apparence des pores dilatés et à prévenir l'apparition de nouvelles imperfections.",
    "En complément, un sérum hydratant et éclatant sera parfait pour apporter une hydratation en profondeur et raviver l'éclat du teint. Sa formule anti-taches aidera également à atténuer les taches pigmentaires pour une peau plus lumineuse et uniforme.",
    "Un complément de soin ciblé pour traiter les pores dilatés. Ce produit aidera à resserrer les pores et à affiner le grain de peau, pour une peau plus lisse et uniforme. Il complète parfaitement le rituel de soin pour une peau mature en quête de perfectionnement.",
    "Un complément anti-rides et anti-imperfections, idéal pour traiter les rides profondes et les imperfections cutanées. Ce complément aidera à lisser la peau, à réduire les imperfections et à améliorer la texture de la peau pour un teint plus uniforme et lumineux.",
    "Un complément de soin pour resserrer les pores et améliorer la texture de la peau. Ce produit aidera à réduire l'apparence des pores dilatés et à affiner le grain de peau, pour une peau plus lisse et plus uniforme.",
    "Un complément de traitement anti-taches, hydratant et apaisant, pour une peau éclatante et uniforme. Ce produit aide à atténuer les taches pigmentaires, à hydrater en profondeur et à apaiser les peaux sensibles, tout en complétant l'action des autres soins cosmétiques du visage.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs régulateurs et éclaircissants pour cibler spécifiquement les problématiques de sensibilité, de taches et d'imperfections. Ce complément aidera à resserrer les pores et à retrouver un teint éclatant et uniforme.",
    "En complément, un sérum éclat et anti-taches peut être utilisé pour cibler les imperfections pigmentaires et raviver l'éclat du teint. Sa texture légère et non grasse en fait un allié idéal pour une peau jeune et mixte. Ce sérum aidera à uniformiser le teint et à atténuer les taches tout en apportant une sensation de fraîcheur à la peau.",
    "Un complément de soin éclat et hydratation, idéal pour raviver l'éclat du teint et hydrater en profondeur la peau. Il aide également à atténuer les taches pigmentaires et à resserrer les pores.",
    "Un complément de traitement pour les taches pigmentaires et les imperfections, formulé pour agir en synergie avec les autres produits de la routine. Sa formule concentrée aide à estomper les taches brunes et à réduire l'apparence des imperfections, pour une peau plus uniforme et lumineuse.",
    "Un complément de soin ciblé pour traiter les imperfections cutanées. Sa formule concentrée en actifs purifiants et régulateurs aide à réduire l'apparence des imperfections et à affiner le grain de peau. Il convient parfaitement aux peaux matures sujettes aux rides et aux imperfections.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs régulateurs et en agents éclatants pour traiter les pores dilatés et redonner de l'éclat à la peau. Ce complément aidera à affiner le grain de peau et à uniformiser le teint.",
    "Un complément de traitement ciblé pour les taches pigmentaires, adapté aux peaux sensibles. Ce complément aidera à atténuer les taches brunes, à uniformiser le teint et à prévenir l'apparition de nouvelles taches, tout en apaisant et en protégeant la peau des agressions extérieures.",
    "En complément, un sérum hydratant et anti-imperfections pour cibler les zones sujettes aux imperfections et aux pores dilatés. Sa texture légère pénètre rapidement dans la peau et régule l'excès de sébum tout en apportant une hydratation en profondeur. Ce sérum aidera à uniformiser le teint et à réduire les imperfections pour une peau plus lisse et éclatante.",
    "Un complément de soin régulateur de sébum, idéal pour les peaux mixtes à grasses. Sa formule légère et non comédogène aide à matifier la peau, resserrer les pores et prévenir l'apparition d'imperfections. Parfait pour compléter la routine de soin et maintenir une peau éclatante et équilibrée.",
    "En complément, un sérum éclat et anti-imperfections pour une peau lumineuse et uniforme. Ce sérum aidera à réduire les taches, les imperfections et les pores dilatés, tout en apportant de l'éclat à la peau.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs hydratants et régulateurs sera idéal pour renforcer l'efficacité du traitement. Ce sérum aidera à hydrater en profondeur, à réguler la production de sébum et à resserrer les pores pour une peau plus lisse et éclatante.",
    "Un complément de soin spécifique pour réguler l'excès de sébum et réduire les imperfections cutanées. Sa formule légère et non comédogène aide à resserrer les pores dilatés, à matifier la peau et à prévenir l'apparition de nouvelles imperfections. Idéal pour compléter le rituel de soin quotidien des peaux matures sujettes aux brillances et aux imperfections.",
    "En complément, un sérum anti-imperfections peut être utilisé pour cibler spécifiquement les problèmes de peau tels que les imperfections et les rougeurs. Ce sérum aidera à purifier la peau et à réduire l'apparence des boutons.",
    "En complément, un sérum éclat et anti-taches pour un teint uniforme et lumineux. Ce sérum aidera à atténuer les taches pigmentaires tout en apportant de la luminosité à la peau. Parfait pour compléter le traitement global de la peau et obtenir un teint éclatant et uniforme.",
    "En complément, un sérum concentré en actifs anti-imperfections pour cibler spécifiquement les zones à problèmes. Ce sérum aidera à réduire l'apparence des imperfections, à resserrer les pores dilatés et à améliorer la texture de la peau. Il convient parfaitement pour une utilisation ciblée sur les zones concernées.",
    "Un complément de soin régulateur de sébum et de pores, idéal pour maintenir l'équilibre de la peau mixte à grasse. Sa texture légère et non grasse aide à matifier la peau, resserrer les pores et prévenir l'apparition de nouvelles imperfections. Parfait pour compléter la routine de soin et garantir une peau nette et éclatante.",
    "En complément, un sérum hydratant et éclatant serait idéal pour répondre aux besoins spécifiques de cette peau mature. Formulé pour hydrater en profondeur, illuminer le teint et atténuer les taches pigmentaires, ce sérum apportera à la peau les nutriments essentiels pour retrouver jeunesse et éclat.",
    "En complément, un sérum anti-rides et anti-âge sera recommandé pour traiter les rides et ridules tout en stimulant la régénération cellulaire. Ce sérum apportera une hydratation en profondeur et aidera à lisser la peau pour un teint plus uniforme et lumineux.",
    "En complément, un sérum concentré en actifs régulateurs et purifiants pour une action ciblée sur les imperfections et les pores dilatés. Ce sérum apportera une hydratation légère tout en régulant la production de sébum pour une peau plus nette et éclatante.",
    "Un complément de soin perfecteur de peau, ciblant les pores dilatés et les taches pigmentaires. Sa formule légère et non comédogène aide à affiner le grain de peau, à estomper les taches et à réduire l'apparence des pores pour une peau plus lisse et uniforme.",
    "Un complément hydratant et anti-imperfections, parfait pour les peaux matures en quête d'hydratation et de réduction des imperfections. Ce complément aidera à hydrater en profondeur, à atténuer les imperfections et à resserrer les pores.",
    "Un complément de traitement spécifique pour réduire l'apparence des pores dilatés. Sa formule légère et non comédogène aide à affiner le grain de peau et à matifier la zone T. Il régule l'excès de sébum et laisse la peau lisse et uniforme.",
    "Un complément hydratant et correcteur, idéal pour les peaux sensibles et sujettes aux taches. Ce complément aidera à hydrater en profondeur, à atténuer les rides et à réduire l'apparence des pores pour une peau plus lisse et uniforme.",
    "En complément, un sérum concentré en actifs anti-rides et anti-pores dilatés peut être utilisé pour cibler spécifiquement ces problématiques. Ce sérum aidera à lisser les rides naissantes, resserrer les pores et améliorer la texture de la peau. Il convient parfaitement à une peau jeune de 21 ans, en apportant une action ciblée pour un teint plus uniforme et éclatant.",
    "En complément, un sérum hydratant et apaisant sera parfait pour renforcer l'hydratation de la peau et réduire les rougeurs et irritations. Ce sérum aidera à maintenir l'éclat naturel de la peau tout en la protégeant des agressions extérieures.",
    "Un complément de soin spécifique pour réguler l'excès de sébum, resserrer les pores dilatés et traiter les imperfections cutanées. Sa formule légère et non comédogène convient parfaitement aux peaux matures sujettes aux imperfections. Il aide à retrouver une peau plus lisse et équilibrée.",
    "En complément, un sérum hydratant et apaisant sera idéal pour renforcer l'hydratation de la peau et réduire les signes de sensibilité. Ce sérum aidera à apaiser les rougeurs et les irritations tout en maintenant l'équilibre hydrique de la peau.",
    "Un complément anti-âge et éclat, idéal pour les peaux matures en quête de luminosité. Ce produit aidera à atténuer les taches pigmentaires, à lisser les rides et à raviver l'éclat du teint pour une peau visiblement plus jeune et lumineuse.",
    "En complément, un sérum concentré en actifs régulateurs et hydratants peut être utilisé pour cibler spécifiquement les zones sujettes aux imperfections et aux taches pigmentaires. Ce sérum aide à affiner le grain de peau et à uniformiser le teint.",
    "Un complément de soin perfecteur de peau, spécialement conçu pour les peaux sensibles et sujettes aux pores dilatés. Ce produit agira en profondeur pour resserrer les pores, affiner le grain de peau et apaiser les rougeurs. Il complétera parfaitement votre routine de soins pour une peau visiblement plus lisse et uniforme.",
    "Un complément de soin ciblé pour traiter les pores dilatés. Sa formule concentrée en actifs astringents et purifiants aide à resserrer les pores, à affiner le grain de peau et à prévenir l'apparition de nouvelles imperfections.",
    "Un complément de soin pour resserrer les pores et améliorer l'éclat du teint. Ce produit aide à réduire l'apparence des pores dilatés tout en apportant de l'éclat à la peau. Parfait pour compléter votre routine de soins du visage.",
    "Un complément hydratant et apaisant, parfait pour les peaux sensibles et déshydratées. Ce complément apportera une hydratation en profondeur tout en apaisant les irritations et les rougeurs. Sa formule légère conviendra parfaitement pour traiter les pores dilatés et améliorer la texture de la peau.",
    "En complément, un traitement anti-rides ciblé pour les premiers signes de l'âge, ainsi qu'un traitement anti-taches pour uniformiser le teint et atténuer les taches pigmentaires. Ces deux produits aideront à prévenir et à corriger les signes visibles du vieillissement cutané.",
    "Un complément anti-rides et anti-taches, idéal pour lutter contre les signes visibles du vieillissement cutané. Ce complément aidera à réduire l'apparence des rides, des taches pigmentaires et des imperfections, tout en apportant de l'éclat à la peau.",
    "Un complément de traitement éclat et hydratation, idéal pour booster l'éclat naturel de la peau et renforcer son hydratation. Sa texture légère pénètre rapidement et laisse la peau douce, souple et lumineuse. Il aide à atténuer les taches pigmentaires et à resserrer les pores dilatés.",
    "En complément, un sérum anti-taches et anti-imperfections peut être utilisé pour cibler les zones spécifiques nécessitant une action ciblée. Ce sérum concentré en actifs éclaircissants et purifiants aidera à uniformiser le teint et à réduire l'apparence des imperfections pour une peau plus lumineuse et plus lisse.",
    "Un complément de traitement pour resserrer les pores dilatés. Sa formule concentrée agit en profondeur pour réduire l'apparence des pores et affiner le grain de peau. Idéal pour compléter le rituel de soins et obtenir une peau lisse et uniforme.",
    "En complément, un traitement anti-taches ciblé peut être utilisé pour traiter efficacement les taches pigmentaires. Sa formule concentrée agit en profondeur pour estomper les taches existantes et prévenir l'apparition de nouvelles. À utiliser en complément du soin régulateur de pores pour une peau visiblement plus uniforme et lumineuse.",
    "En complément de votre routine de soins, un sérum concentré en actifs régulateurs et anti-imperfections pour cibler les zones spécifiques du visage. Ce sérum aidera à réduire l'apparence des pores dilatés, à contrôler l'excès de sébum et à traiter les imperfections localisées. Sa texture légère pénétrera rapidement dans la peau pour une action ciblée et efficace.",
    "Un complément de soin pour resserrer les pores dilatés et unifier le teint. Sa texture légère et non grasse pénètre rapidement dans la peau pour la matifier et lui donner un aspect plus lisse. Idéal pour compléter le rituel de soin quotidien et obtenir un teint éclatant et uniforme.",
    "Un complément de traitement anti-âge et anti-imperfections, idéal pour les peaux sensibles et sujettes aux pores dilatés. Ce complément aidera à réduire l'apparence des rides, des taches et des imperfections, tout en resserrant les pores et en apportant de l'éclat à la peau.",
    "En complément du nettoyant et du soin, un sérum anti-taches et anti-rides peut être utilisé pour cibler spécifiquement ces problématiques. Ce sérum concentré en actifs régénérants et éclaircissants aidera à uniformiser le teint, à atténuer les rides et à prévenir l'apparition de nouvelles imperfections. Il complètera parfaitement la routine de soins pour une peau éclatante et hydratée.",
    "En complément, un sérum concentré en actifs anti-âge et apaisants pour une action renforcée sur les rides, la sensibilité et les imperfections. Ce sérum agira en profondeur pour une peau visiblement plus lisse, uniforme et éclatante.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs hydratants et anti-imperfections sera idéal pour renforcer l'efficacité du traitement. Ce sérum aidera à lisser la peau, atténuer les rides et les taches, tout en régulant l'excès de sébum pour une peau éclatante et uniforme.",
    "Un complément de soin ciblé pour traiter les imperfections et apporter de l'éclat à la peau. Sa formule concentrée agit efficacement sur les taches pigmentaires et les imperfections tout en ravivant l'éclat naturel du teint. Il convient parfaitement aux peaux sensibles et réactives.",
    "Un complément de soin ciblé pour traiter les taches pigmentaires et les imperfections. Sa formule légère et non comédogène aide à atténuer les taches tout en régulant l'excès de sébum et en resserrant les pores. Parfait pour compléter le rituel de soin et obtenir une peau plus uniforme et éclatante.",
    "Un complément de traitement pour l'éclat et l'hydratation de la peau, idéal pour réduire les taches pigmentaires et resserrer les pores. Ce complément aidera à uniformiser le teint et à améliorer la texture de la peau pour un teint lumineux et éclatant.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs anti-rides et anti-taches pour une action ciblée et renforcée. Ce complément aidera à lisser la peau, à atténuer les taches pigmentaires et à réduire l'apparence des pores pour une peau visiblement plus jeune et éclatante.",
    "Un complément de traitement anti-taches et anti-pores, formulé pour cibler les hyperpigmentations et les pores dilatés. Ce complément agira en synergie avec les autres produits pour uniformiser le teint, réduire les taches brunes et resserrer les pores pour une peau plus lisse et éclatante.",
    "Un complément anti-imperfections, idéal pour traiter les problèmes d'imperfections et de pores dilatés. Ce complément aidera à purifier la peau en réduisant l'apparence des imperfections et en resserrant les pores pour une peau plus lisse et plus nette.",
    "Un complément de soin anti-rides et anti-taches, conçu pour cibler les zones spécifiques du visage présentant des imperfections et des pores dilatés. Sa formule concentrée en actifs anti-âge et éclaircissants aide à réduire visiblement les rides, les taches et les imperfections, tout en resserrant les pores pour une peau plus lisse et uniforme.",
    "Un complément de soin régulateur de sébum, idéal pour les peaux sujettes aux imperfections. Sa formule légère et non comédogène aide à réduire l'excès de sébum, resserrer les pores et prévenir l'apparition de boutons. Il laisse la peau matifiée et lisse, pour un teint uniforme et sans brillance.",
    "Un complément de traitement ciblé pour les imperfections et les pores dilatés. Ce produit aidera à réduire l'apparence des imperfections et des pores, tout en régulant l'excès de sébum pour une peau plus lisse et plus uniforme.",
    "Un complément de soin perfecteur de peau, idéal pour resserrer les pores dilatés et affiner le grain de peau. Sa formule matifiante régule l'excès de sébum tout en hydratant la peau en profondeur. Il laisse la peau lisse, douce et éclatante de santé.",
    "Un complément anti-taches et anti-âge, idéal pour traiter les hyperpigmentations et les premières rides. Sa texture légère pénètre rapidement dans la peau pour un effet lissant et unifié. Parfait pour une peau en quête d'éclat et de jeunesse.",
    "Un complément de soin hydratant et matifiant, idéal pour réguler l'excès de sébum et minimiser l'apparence des pores dilatés. Sa texture légère pénètre rapidement dans la peau, la laissant douce, lisse et parfaitement hydratée.",
    "En complément, un sérum hydratant et éclatant sera parfait pour apporter une hydratation en profondeur, tout en illuminant le teint et en atténuant les rides. Ce sérum aidera à prévenir la formation de nouvelles taches tout en réduisant visiblement les signes de l'âge.",
    "En complément, un sérum régulateur de pores et matifiant peut être utilisé pour affiner le grain de peau et minimiser l'apparence des pores. Ce sérum aidera à contrôler la brillance tout en laissant la peau douce et lisse.",
    "En complément, un sérum hydratant et anti-taches pour une peau parfaitement hydratée, uniforme et lumineuse. Ce sérum concentré en actifs hydratants et éclaircissants aidera à réduire les taches pigmentaires tout en maintenant une hydratation optimale de la peau.",
    "Un complément de soin hydratant et matifiant, idéal pour resserrer les pores dilatés et réduire les imperfections cutanées. Sa formule non comédogène convient à tous les types de peau et aide à maintenir un équilibre hydrolipidique optimal.",
    "Un complément de traitement pour les taches pigmentaires, les imperfections et la régulation de la peau. Sa formule ciblée aide à atténuer les taches brunes, à réduire les imperfections et à réguler l'excès de sébum. Il complète efficacement les soins anti-rides et hydratants pour une peau éclatante et uniforme.",
    "Un complément de soin ciblé pour traiter les rides et les pores dilatés. Sa formule concentrée agit en profondeur pour lisser les rides et resserrer les pores, tout en régulant l'excès de sébum. Parfait pour une peau mature, ce complément aide à améliorer la texture de la peau et à réduire les imperfections.",
    "En complément du nettoyant et du soin anti-âge, un sérum régulateur de sébum est recommandé pour traiter les zones de brillance et de pores dilatés. Sa texture légère pénètre rapidement dans la peau et aide à matifier le teint tout en resserrant les pores.",
    "Un complément de soin hydratant et apaisant, idéal pour les peaux sensibles et sujettes aux rougeurs. Ce produit aidera à réguler la sensibilité cutanée tout en apportant une hydratation en profondeur pour une peau plus confortable et éclatante.",
    "Une crème de jour matifiante et régulatrice de sébum, parfaite pour les peaux jeunes à tendance grasse. Cette crème légère hydratera la peau en profondeur, tout en resserrant les pores et en régulant l'excès de sébum pour un teint matifié et uniforme.",
    "En complément du nettoyant et du soin, un sérum concentré en actifs anti-âge et hydratants peut être utilisé pour renforcer les effets du traitement. Ce sérum aidera à lisser les rides, à uniformiser le teint et à apaiser les peaux sensibles.",
    "Un complément de traitement anti-taches, formulé pour cibler les hyperpigmentations et unifier le teint. Sa formule concentrée agit en profondeur pour atténuer les taches brunes et redonner de la luminosité à la peau. Idéal pour compléter un rituel de soin anti-âge et retrouver un teint uniforme et lumineux.",
    "En complément, un sérum éclat et hydratant sera idéal pour apporter de la luminosité à la peau et renforcer son hydratation. Ce sérum aidera à atténuer les taches pigmentaires et à resserrer les pores pour une peau plus lisse et uniforme.",
    "Un complément de traitement pour les taches pigmentaires et les imperfections. Ce produit aidera à uniformiser le teint et à réduire l'apparence des taches, tout en améliorant la texture de la peau.",
    "Un complément de soin hydratant et matifiant, idéal pour resserrer les pores et apporter de l'éclat à la peau. Ce produit aidera à maintenir l'hydratation tout en réduisant l'apparence des pores dilatés pour une peau lisse et lumineuse.",
    "Un complément de traitement intensif pour les pores dilatés, les taches et les imperfections. Ce complément ciblera spécifiquement les pores dilatés, les taches pigmentaires et les imperfections pour une peau plus lisse, plus uniforme et plus éclatante.",
    "Un complément de traitement pour réguler l'excès de sébum et resserrer les pores. Il aide à matifier la peau et à prévenir l'apparition de nouvelles imperfections.",
    "Un complément de traitement anti-âge et hydratant, idéal pour les peaux sensibles et matures. Ce complément aidera à réduire l'apparence des rides, à réguler la sensibilité de la peau et à resserrer les pores pour une peau plus lisse et plus jeune."
  ];
const   randomTextEn = [
    "An intensive anti-wrinkle treatment, rich in regenerating and moisturizing active ingredients. This treatment will help to visibly reduce wrinkles and fine lines, while improving the elasticity and firmness of the skin.",
    "A regulating and radiant treatment, designed to treat skin sensitivity and regulation issues. Its moisturizing and anti-blemish formula helps to reduce redness, even out skin tone and reduce imperfections. Perfect for bringing comfort and luminosity to the skin.",
    "A powerful anti-wrinkle and anti-blemish treatment, designed to regenerate the skin and reduce the signs of aging. Enriched with regulating active ingredients, this treatment will help to smooth wrinkles, even out skin tone and reduce pigment spots. It will bring radiance and firmness to the skin while protecting it from external aggressions.",
    "An intensive anti-wrinkle and anti-blemish treatment, designed to target the visible signs of skin aging. Its regulating formula helps to reduce the appearance of wrinkles and imperfections, while regulating excess sebum for smoother, more even skin.",
    "An intense moisturizing treatment for sensitive skin, formulated to regulate skin sensitivity and provide immediate comfort. This treatment will help reduce redness and irritation, while strengthening the skin barrier.",
    "A regulating and moisturizing treatment, ideal for treating sebum regulation and hydration issues. This treatment will help balance sebum production, deeply moisturize and soothe sensitive skin. It will also help reduce blemishes and improve skin radiance.",
    "A comprehensive anti-aging treatment, specially designed for men aged 40 and over. This regenerating and moisturizing treatment helps reduce wrinkles, regulate sebum production, soothe sensitive skin and reduce the appearance of spots and blemishes. It leaves the skin brighter, smoother and more even.",
    "A global anti-aging treatment, enriched with regenerating and antioxidant active ingredients, to fight against wrinkles, pigment spots and imperfections. Its moisturizing and soothing formula is perfectly suited to sensitive skin, while providing a natural glow and regulating sebum production.",
    "An anti-aging and radiance treatment, designed to treat wrinkles, pigment spots and imperfections. Its formula rich in regenerating and illuminating active ingredients will help restore radiance and youthfulness to mature skin.",
  ];


export const  getRandomText =(lang) => {
    if(lang === 'english') {
      randomText = randomTextEn;
    }
    const random = Math.floor(Math.random() * randomText.length);
    return randomText[random];
  }